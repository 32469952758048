import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import { fetchNcrGeneralProto, intlFormat } from "../../../helpers";
import { MasterJavaBaseModel, PpicNcrGeneral } from "../../../masterbigsystem";
import { ExtUser } from "../../../models/model";

/**
 * NcrGeneralPrintPage
 * Renders a "printable" layout of the NCR data, matching the style from your screenshot,
 * with each table wrapped in a .border.border-dark and each cell having its own .border.border-dark.
 */
const NcrGeneralPrintPage = () => {
  const { id } = useParams<{ id?: string }>();
  const ctx = useContext(AppContext);

  const [ncr, setNcr] = useState<PpicNcrGeneral>(
    PpicNcrGeneral.fromPartial({
      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({}),
      problems: [],
      problemCorrectiveActions: [],
      problemPreventiveActions: [],
    })
  );

  useEffect(() => {
    ctx?.setAppBarVisible(false);
    if (id) {
      loadData(id);
    }
  }, [id]);

  const loadData = async (ncrId: string) => {
    const data = await fetchNcrGeneralProto({
      apiKey: ctx?.apiKey ?? "",
      id: ncrId,
    });
    if (data) {
      setNcr(data);
    }
  };

  // Helper to get a user’s display name from extUsers by ID
  const getUserDisplay = (id?: string | number) => {
    if (!id) return "";
    const user = ctx?.extUsers.find((u) => `${u.id}` === `${id}`);
    if (!user) return "";
    return `${user.name} (${user.departmentName})`;
  };

  return (
    <div className="container p-3">
      {/* A button to trigger the browser print dialog, if desired */}
      <div className="d-print-none mb-3">
        <button className="btn btn-primary" onClick={() => window.print()}>
          Print this page
        </button>
      </div>

      {/* Title / Header */}
      <div className="text-center">
        <h4>NON CONFORMANCE REPORT (NCR)</h4>
        <hr />
      </div>

      {/* Basic Info Row */}
      <div className="d-flex justify-content-between">
        <div>
          <strong>NO. NCR:</strong>{" "}
          {ncr.masterJavaBaseModel?.id ? ncr.masterJavaBaseModel.id : "----"}
        </div>
        <div>
          <strong>NC ISSUER:</strong> {getUserDisplay(ncr.extIssuerId)}
        </div>
        <div>
          <strong>Created Date:</strong>{" "}
          {ncr.masterJavaBaseModel?.createdAt
            ? intlFormat({
                date: ncr.masterJavaBaseModel?.createdAt,
                dateStyle: "medium",
                timeStyle: "short",
              })
            : "----"}
        </div>
        <div>
          <strong>Created By:</strong>{" "}
          {getUserDisplay(ncr.masterJavaBaseModel?.extCreatedById)}
        </div>
      </div>

      {/* Second row of info */}
      <div className="d-flex justify-content-between mt-2">
        <div>
          <strong>NC Date:</strong>{" "}
          {ncr.ncrDate
            ? intlFormat({ date: ncr.ncrDate, dateStyle: "medium" })
            : ""}
        </div>
        <div>
          <strong>NC Area:</strong> {ncr.ncArea || ""}
        </div>
        <div>
          <strong>QC Area</strong> {/* or any extra field */}
        </div>
      </div>

      <hr className="border border-dark" />

      {/* PROBLEMS Section */}
      <div className="mt-3">
        <strong>PROBLEM(S)</strong>
        <div className=" p-2 mt-2">
          <table className="table table-sm mb-0">
            <thead>
              <tr>
                <th className="border border-dark" style={{ width: "5%" }}>
                  #
                </th>
                <th className="border border-dark" style={{ width: "55%" }}>
                  Problem Description
                </th>
                <th className="border border-dark" style={{ width: "20%" }}>
                  PIC Problem
                </th>
                <th className="border border-dark" style={{ width: "20%" }}>
                  Type
                </th>
              </tr>
            </thead>
            <tbody>
              {(ncr.problems ?? []).map((problem, idx) => (
                <tr key={problem.masterJavaBaseModel?.id || idx}>
                  <td className="border border-dark">{idx + 1}</td>
                  <td
                    className="border border-dark"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {problem.description || ""}
                  </td>
                  <td className="border border-dark">
                    {getUserDisplay(problem.extPicId)}
                  </td>
                  <td className="border border-dark">{problem.type || ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* root cause analysis */}
      <div className="mt-3">
        <strong>ROOT CAUSE ANALYSIS</strong>
        <div
          className="border border-dark p-2"
          style={{ minHeight: 80, whiteSpace: "pre-wrap" }}
        >
          {ncr.rootCauseAnalysis || ""}
        </div>
      </div>

      {/* ACTION PLAN */}
      <div className="mt-4">
        <strong>ACTION PLAN</strong>
        {/* Corrective Actions */}
        <div className="mt-2">
          <strong>A. CORRECTIVE ACTION</strong>
          <div className=" p-2 mt-1">
            <table className="table table-sm mb-0">
              <thead>
                <tr>
                  <th className="border border-dark" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="border border-dark" style={{ width: "45%" }}>
                    Description
                  </th>
                  <th className="border border-dark" style={{ width: "15%" }}>
                    Deadline
                  </th>
                  <th className="border border-dark" style={{ width: "15%" }}>
                    NC Issuer
                  </th>
                  <th className="border border-dark" style={{ width: "20%" }}>
                    PIC Problem
                  </th>
                </tr>
              </thead>
              <tbody>
                {(ncr.problemCorrectiveActions ?? []).map((c, idx) => (
                  <tr key={c.masterJavaBaseModel?.id || idx}>
                    <td className="border border-dark">{idx + 1}</td>
                    <td
                      className="border border-dark"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {c.description || ""}
                    </td>
                    <td className="border border-dark">
                      {intlFormat({
                        date: c.deadline,
                        dateStyle: "medium",
                        timeStyle: "short",
                      })}
                    </td>
                    <td className="border border-dark">
                      {getUserDisplay(ncr.extIssuerId)}
                    </td>
                    <td className="border border-dark">
                      {getUserDisplay(c.extPicId)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Preventive Actions */}
        <div className="mt-3">
          <strong>B. PREVENTIVE ACTION</strong>
          <div className=" p-2 mt-1">
            <table className="table table-sm mb-0">
              <thead>
                <tr>
                  <th className="border border-dark" style={{ width: "5%" }}>
                    #
                  </th>
                  <th className="border border-dark" style={{ width: "45%" }}>
                    Description
                  </th>
                  <th className="border border-dark" style={{ width: "15%" }}>
                    Deadline
                  </th>
                  <th className="border border-dark" style={{ width: "15%" }}>
                    NC Issuer
                  </th>
                  <th className="border border-dark" style={{ width: "20%" }}>
                    PIC Problem
                  </th>
                </tr>
              </thead>
              <tbody>
                {(ncr.problemPreventiveActions ?? []).map((p, idx) => (
                  <tr key={p.masterJavaBaseModel?.id || idx}>
                    <td className="border border-dark">{idx + 1}</td>
                    <td
                      className="border border-dark"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {p.description || ""}
                    </td>
                    <td className="border border-dark">
                      {intlFormat({
                        date: p.deadline,
                        dateStyle: "medium",
                        timeStyle: "short",
                      })}
                    </td>
                    <td className="border border-dark">
                      {getUserDisplay(ncr.extIssuerId)}
                    </td>
                    <td className="border border-dark">
                      {getUserDisplay(p.extPicId)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Signature block row */}

      <hr className="border border-dark" />
      <div className="d-flex justify-content-between mt-2">
        <div>
          <div className="border border-dark p-2" style={{ minHeight: 80 }}>
            <strong>Monitored By: </strong>
            <br /> <div className="my-5"></div>
            {getUserDisplay(ncr.extMonitoredById)}
            <br />
          </div>
        </div>
        <div>
          <div className="border border-dark p-2" style={{ minHeight: 80 }}>
            <strong>Created By: </strong>
            <br /> <div className="my-5"></div>
            {getUserDisplay(ncr.masterJavaBaseModel?.extCreatedById)}
            <br />
          </div>
        </div>

        <div>
          <div className="border border-dark p-2" style={{ minHeight: 80 }}>
            <strong>Closed By: </strong>
            <br /> <div className="my-5"></div>
            {getUserDisplay(ncr.extClosedById)}
            <br />
          </div>
        </div>
        <div>
          <div className="border border-dark p-2" style={{ minHeight: 80 }}>
            <strong>Approved By: </strong>
            <br />
            <div className="my-5"></div>
            {getUserDisplay(ncr.extApprovedById)}
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NcrGeneralPrintPage;
