import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import {
  fetchApps,
  fetchDepartmentTemplateCategories,
  fetchDepartmentTemplateGroups,
  fetchDepartmentTemplates,
  fetchDepartmentTemplatesView,
  fetchPanelCodeDepartmentTemplatePresetsProto,
  fetchRcemDepartmentsProto,
} from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicDepartmentTemplateItem,
  PpicDepartmentTemplates,
  PpicPanelCodeDepartmentTemplatePreset,
  PpicPanelCodeDepartmentTemplatePresetDeptItem,
  PpicPanelCodeDepartmentTemplatePresetDepts,
  PpicPanelCodeDepartmentTemplatePresets,
  PpicRcemDepartments,
  PpicRcemSubProcess,
} from "../../../masterbigsystem";
import { v4 as uuidv4, v4 } from "uuid";
import {
  DepartmentTemplateCategory,
  DepartmentTemplateGroup,
  DepartmentTemplateItem,
  DepartmentTemplateItemView,
  DepartmentTemplatePostBody,
  DepartmentTemplateView,
  ExtApp,
  ExtDepartment,
  ExtUser,
} from "../../../models/model";
import Select from "react-select";
import ColorHash from "color-hash";
import { Add, Delete } from "@material-ui/icons";
import {
  initialDepartmentTemplate,
  initialDepartmentTemplateGroup,
  initialDepartmentTemplateGroupItem,
  initialTemplateCategory,
} from "../../../models/modelinitials";
import { decodedTextSpanIntersectsWith } from "typescript";
import { borderRadius } from "react-select/src/theme";
import { Spinner } from "react-bootstrap";
import { METHODS } from "http";

type DepartmentTemplatePageViewType =
  | "Editor"
  | "Preset"
  | "Template Group"
  | "Template Category"
  | "Group Category";

const DepartmentTemplatePage = () => {
  const ctx = useContext(AppContext);

  const [deptTemplatePresets, setDeptTemplatePresets] = useState(
    PpicPanelCodeDepartmentTemplatePresets.fromPartial({ presets: [] })
  );
  const [viewType, setViewType] =
    useState<DepartmentTemplatePageViewType>("Editor");
  const [loading, setLoading] = useState(false);
  const [departmentTemplates, setDepartmentTemplates] = useState<
    DepartmentTemplateView[] | null | undefined
  >(null);
  const [departmentTemplateDeleteIds, setDepartmentTemplateDeleteIds] =
    useState<number[]>([]);
  const [departmentTemplateItemDeleteIds, setDepartmentTemplateItemDeleteIds] =
    useState<number[]>([]);
  const [rcemDepartments, setRcemDepartments] =
    useState<PpicRcemDepartments | null>(null);
  const [selectedDeptTemplateOrdering, setSelectedDeptTemplateOrdering] =
    useState<number | null>(null);
  const [
    selectedDepartmentTemplateItemUuid,
    setselectedDepartmentTemplateItemUuid,
  ] = useState<string | null | undefined>(null);
  const [deptTemplatePresetItemDeleteIds, setDeptTemplatePresetItemDeleteIds] =
    useState<string[]>([]);
  const [deptFilterType, setDeptFilterType] = useState<
    "Project" | "NonProject" | "All"
  >("All");
  const [showHidden, setShowHidden] = useState(false);
  const [, refresh] = useState(false);
  const apps = useRef([] as ExtApp[]);
  const templateGroups = useRef([] as DepartmentTemplateGroup[]);

  const templateCategories = useRef([] as DepartmentTemplateCategory[]);
  const selectedTemplateCategories = useRef(
    null as DepartmentTemplateCategory | null
  );
  const render = () => {
    refresh((n) => !n);
  };
  useEffect(() => {
    handleInit();
  }, []);

  const fetchDepartmentTemplateGroupsData = async () => {
    const d = await fetchDepartmentTemplateGroups({
      apiKey: ctx?.apiKey ?? "",
    });
    if (d) {
      templateGroups.current = d;
      render();
    }
  };

  const handleInit = async () => {
    try {
      const [departmentTemplatesData] = await Promise.all([
        fetchDepartmentTemplatesView({
          apiKey: ctx?.apiKey ?? "",
          categoryAll: true,
          categoryId: selectedTemplateCategories.current?.id,
        }),
      ]);

      setDepartmentTemplates(departmentTemplatesData);

      fetchRcemDepartmentsData();
      fetchPresetsData();
      fetchAppsData();
      fetchDepartmentTemplateGroupsData();
      fetchTemplateCategoriesData();
      // fetchTemplateGroupCategoriesData();
    } catch (e) {
    } finally {
    }
  };

  function insertDeptTemplate(
    allTemplates: DepartmentTemplateView[],
    referenceTemplate: DepartmentTemplateView,
    type: "above" | "below"
  ): DepartmentTemplateView[] {
    const refOrder = referenceTemplate.departmentTemplate?.ordering ?? 0;
    const refCat = referenceTemplate.departmentTemplate?.category ?? null;

    // Clone and sort ascending
    let newDTs = [...allTemplates].sort(
      (a, b) =>
        (a.departmentTemplate?.ordering ?? 0) -
        (b.departmentTemplate?.ordering ?? 0)
    );

    // Decide insertion ordering
    const insertionOrder = type === "above" ? refOrder : refOrder + 1;

    // Shift everything that is >= insertionOrder
    for (let dt of newDTs) {
      const currentOrder = dt.departmentTemplate?.ordering ?? 0;
      if (currentOrder >= insertionOrder) {
        dt.departmentTemplate!.ordering = currentOrder + 1;
      }
    }

    // Create & insert new DepartmentTemplateView
    newDTs.push({
      departmentTemplate: {
        ...initialDepartmentTemplate,
        ordering: insertionOrder,
        category: refCat,
        uuid: v4(),
        name: "", // set default name or leave blank
      },
      departmentTemplateItems: [],
    });

    return newDTs;
  }

  const fetchRcemDepartmentsData = async () => {
    setRcemDepartments(
      await fetchRcemDepartmentsProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const fetchPresetsData = async () => {
    setDeptTemplatePresets(
      await fetchPanelCodeDepartmentTemplatePresetsProto({
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };

  const fetchAppsData = async () => {
    const d = await fetchApps(ctx?.apiKey ?? "");

    if (d) {
      apps.current = d;
      render();
    }
  };

  const fetchTemplateCategoriesData = async () => {
    const d = await fetchDepartmentTemplateCategories({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      templateCategories.current = d;
      render();
    }
  };

  const ensureNoDuplicateOrderings = () => {
    if (!departmentTemplates) return;

    // Sort templates
    let dtSorted = [...departmentTemplates].sort((a, b) => {
      const ao = a.departmentTemplate?.ordering ?? 0;
      const bo = b.departmentTemplate?.ordering ?? 0;
      return ao - bo;
    });

    // Re-index templates
    dtSorted.forEach((dt, index) => {
      if (dt.departmentTemplate) {
        dt.departmentTemplate.ordering = index + 1; // 1-based
      }
      // Re-sort items
      if (dt.departmentTemplateItems) {
        const sortedItems = [...dt.departmentTemplateItems].sort((a, b) => {
          const ao = a.departmentTemplateItem?.ordering ?? 0;
          const bo = b.departmentTemplateItem?.ordering ?? 0;
          return ao - bo;
        });
        // Re-index items
        sortedItems.forEach((it, idx) => {
          if (it.departmentTemplateItem) {
            it.departmentTemplateItem.ordering = idx + 1; // 1-based
          }
        });
        dt.departmentTemplateItems = sortedItems;
      }
    });

    setDepartmentTemplates(dtSorted);
  };

  const handleSave = async () => {
    try {
      ensureNoDuplicateOrderings();

      setLoading(true);

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/departmenttemplates-save`,
        {
          method: "post",
          headers: {
            authorization: ctx?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            departmentTemplates: departmentTemplates,
            departmentTemplateDeleteIds: departmentTemplateDeleteIds,
            departmentTemplateItemDeleteIds: departmentTemplateItemDeleteIds,
          } as DepartmentTemplatePostBody),
        }
      );

      if (resp.status !== 200) throw await resp.text();

      // save dept preset
      const respPreset = await fetch(
        `${process.env.REACT_APP_BASE_URL}/panelcodedepartmenttemplatepresets-proto-save`,
        {
          method: "post",
          headers: {
            authorization: ctx?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify(
            PpicPanelCodeDepartmentTemplatePresets.toJSON(deptTemplatePresets)
          ),
        }
      );

      // save departmenttemplategroups
      const respdepartmenttemplategroups = await fetch(
        `${process.env.REACT_APP_BASE_URL}/departmenttemplategroupitems-bulk`,
        {
          method: "post",
          headers: {
            authorization: ctx?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify(templateGroups.current),
        }
      );

      // save templatecategories
      const resptemplatecategories = await fetch(
        `${process.env.REACT_APP_BASE_URL}/departmenttemplatecategories-bulk`,
        {
          method: "post",
          headers: {
            authorization: ctx?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify(templateCategories.current),
        }
      );

      await Promise.all(
        deptTemplatePresetItemDeleteIds.map(async (id) => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_BASE_URL}/panelcodedepartmenttemplatepresetdeptitems/${id}/empty`,
              {
                method: "delete",
                headers: { authorization: ctx?.apiKey ?? "" },
              }
            );
          } catch (e) {}
        })
      );

      window.location.reload();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const filteredDeptTemplatesByCategory = departmentTemplates?.filter((t) =>
    selectedTemplateCategories.current
      ? t.departmentTemplate?.category?.id ===
        selectedTemplateCategories.current.id
      : !t.departmentTemplate?.category?.id
  );

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-center">
          <h4>Department Template Editor</h4>

          <div className="mx-3">
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>

        <hr className="border border-dark" />

        {(
          [
            "Editor",
            "Preset",
            "Template Group",
            "Template Category",
            // "Group Category",
          ] as DepartmentTemplatePageViewType[]
        ).map((t) => {
          return (
            <>
              <button
                className={`mx-2 btn btn-sm ${
                  viewType === t ? `btn-primary` : `btn-outline-primary`
                }`}
                onClick={() => {
                  setViewType(t);
                }}
              >
                {t}
              </button>
            </>
          );
        })}

        <div className="d-flex">
          <div className="mr-2">
            <strong>Selected Category: </strong>
          </div>
          <div className="mx-2">
            <button
              onClick={() => {
                selectedTemplateCategories.current = null;
                render();
              }}
              className={`btn btn-sm ${
                selectedTemplateCategories.current === null
                  ? `btn-success`
                  : `btn-outline-success`
              }`}
            >
              Default
            </button>
          </div>
          {templateCategories.current.map((c, i) => {
            return (
              <>
                {" "}
                <div className="mx-2">
                  <button
                    onClick={() => {
                      selectedTemplateCategories.current = c;
                      render();
                    }}
                    className={`btn btn-sm ${
                      selectedTemplateCategories.current?.id === c.id
                        ? `btn-success`
                        : `btn-outline-success`
                    }`}
                  >
                    {c.name}
                  </button>
                </div>
              </>
            );
          })}
        </div>

        <hr className="border border-dark" />

        {(() => {
          switch (viewType) {
            case "Editor":
              return (
                <>
                  <div className="d-flex">
                    <div>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          // When adding a new department template,
                          // find the highest ordering in the array and +1
                          const existing = [...(departmentTemplates ?? [])];
                          let maxOrder =
                            existing.reduce((acc, cur) => {
                              const o = cur.departmentTemplate?.ordering ?? 0;
                              return o > acc ? o : acc;
                            }, 0) || 0;

                          setDepartmentTemplates([
                            ...(departmentTemplates ?? []),
                            {
                              departmentTemplate: {
                                ...initialDepartmentTemplate,
                                uuid: v4(),
                                category: selectedTemplateCategories.current,
                                ordering: maxOrder + 1, // ensure unique
                              },
                              departmentTemplateItems: [],
                            },
                          ]);
                        }}
                      >
                        Add Department Template
                      </button>
                    </div>

                    <div className="d-flex mx-2">
                      <div>Filter:</div>

                      <button
                        className={`btn btn-sm ${
                          deptFilterType === "NonProject"
                            ? `btn-primary`
                            : `btn-outline-primary`
                        }`}
                        onClick={() => {
                          setDeptFilterType("NonProject");
                        }}
                      >
                        Non-project
                      </button>
                      <button
                        className={`btn btn-sm ${
                          deptFilterType === "Project"
                            ? `btn-primary`
                            : `btn-outline-primary`
                        }`}
                        onClick={() => {
                          setDeptFilterType("Project");
                        }}
                      >
                        Project
                      </button>
                      <button
                        className={`btn btn-sm ${
                          deptFilterType === "All"
                            ? `btn-primary`
                            : `btn-outline-primary`
                        }`}
                        onClick={() => {
                          setDeptFilterType("All");
                        }}
                      >
                        All
                      </button>
                    </div>
                  </div>

                  <div>
                    <hr className="border border-dark" />
                  </div>

                  <div
                    className="overflow-auto border border-dark shadow shadow-md"
                    style={{ height: "65vh", resize: "both" }}
                  >
                    <table
                      className="table table-sm"
                      style={{
                        borderCollapse: "separate",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {(() => {
                        const dts = filteredDeptTemplatesByCategory?.filter(
                          (dt) => !dt.departmentTemplate?.hidden
                        );
                        dts?.sort(
                          (a, b) =>
                            (a.departmentTemplate?.ordering ?? 0) -
                            (b.departmentTemplate?.ordering ?? 0)
                        );

                        return dts;
                      })()
                        ?.filter((dt) => {
                          switch (deptFilterType) {
                            case "All":
                              return true;

                            case "Project":
                              return dt.departmentTemplate?.isProject == true;

                            case "NonProject":
                              return !dt.departmentTemplate?.isProject;

                            default:
                              return false;
                          }
                        })
                        .map((dt, i) => {
                          return (
                            <>
                              <tr>
                                <td className="border border-dark">
                                  <button
                                    onClick={() => {
                                      setDepartmentTemplates(
                                        departmentTemplates?.filter(
                                          (dtx) =>
                                            `${dtx.departmentTemplate?.uuid}` !==
                                            `${dt.departmentTemplate?.uuid}`
                                        )
                                      );
                                      setDepartmentTemplateDeleteIds([
                                        ...departmentTemplateDeleteIds,
                                        dt.departmentTemplate?.id ?? 0,
                                      ]);
                                    }}
                                    className="btn btn-danger btn-sm"
                                  >
                                    Delete
                                  </button>
                                </td>

                                <td
                                  className="border border-dark bg-light"
                                  style={{ position: "sticky", left: 0 }}
                                >
                                  <div>
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <div className="d-flex align-items-center">
                                          <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => {
                                              // Adding a new item: set ordering to (current length+1)
                                              // or the largest item ordering +1
                                              const dtItems =
                                                dt.departmentTemplateItems ??
                                                [];
                                              let maxItemOrder = dtItems.reduce(
                                                (acc, cur) => {
                                                  const o =
                                                    cur.departmentTemplateItem
                                                      ?.ordering ?? 0;
                                                  return o > acc ? o : acc;
                                                },
                                                0
                                              );

                                              setDepartmentTemplates(
                                                departmentTemplates?.map(
                                                  (dtx) =>
                                                    dtx &&
                                                    dtx.departmentTemplate &&
                                                    dtx.departmentTemplate
                                                      ?.uuid ===
                                                      dt.departmentTemplate
                                                        ?.uuid
                                                      ? {
                                                          ...dtx,
                                                          departmentTemplateItems:
                                                            [
                                                              ...dtItems,
                                                              {
                                                                departmentTemplateItem:
                                                                  {
                                                                    uuid: uuidv4(),
                                                                    ordering:
                                                                      maxItemOrder +
                                                                      1,
                                                                  } as DepartmentTemplateItem,
                                                              } as DepartmentTemplateItemView,
                                                            ],
                                                        }
                                                      : dtx
                                                )
                                              );
                                            }}
                                          >
                                            Add
                                          </button>
                                          <button
                                            className={`btn btn-sm ${
                                              selectedDeptTemplateOrdering
                                                ? `btn-outline-primary`
                                                : `btn-outline-secondary`
                                            }`}
                                            onClick={() => {
                                              if (
                                                selectedDeptTemplateOrdering
                                              ) {
                                                if (
                                                  selectedDeptTemplateOrdering !==
                                                  dt.departmentTemplate
                                                    ?.ordering
                                                ) {
                                                  const aOrdering =
                                                    selectedDeptTemplateOrdering;
                                                  const bOrdering =
                                                    dt.departmentTemplate
                                                      ?.ordering ?? null;

                                                  setDepartmentTemplates(
                                                    departmentTemplates?.map(
                                                      (dtx) =>
                                                        dtx
                                                          ? {
                                                              ...dtx,
                                                              departmentTemplate:
                                                                dtx.departmentTemplate
                                                                  ? {
                                                                      ...dtx.departmentTemplate,
                                                                      ordering:
                                                                        (() => {
                                                                          switch (
                                                                            dtx
                                                                              .departmentTemplate
                                                                              .ordering
                                                                          ) {
                                                                            case aOrdering:
                                                                              return bOrdering;
                                                                            case bOrdering:
                                                                              return aOrdering;
                                                                            default:
                                                                              return dtx
                                                                                .departmentTemplate
                                                                                .ordering;
                                                                          }
                                                                        })(),
                                                                    }
                                                                  : dtx.departmentTemplate,
                                                            }
                                                          : dtx
                                                    )
                                                  );
                                                }

                                                setSelectedDeptTemplateOrdering(
                                                  null
                                                );
                                              } else {
                                                setSelectedDeptTemplateOrdering(
                                                  dt.departmentTemplate
                                                    ?.ordering ?? null
                                                );
                                              }
                                            }}
                                          >
                                            {selectedDeptTemplateOrdering
                                              ? `Here`
                                              : `Move`}
                                          </button>
                                        </div>
                                        <div className="d-flex align-items-center">
                                          {" "}
                                          <div>
                                            <button
                                              className="btn btn-sm btn-outline-secondary"
                                              onClick={() => {
                                                if (!departmentTemplates) {
                                                  return;
                                                }
                                                const updated =
                                                  insertDeptTemplate(
                                                    departmentTemplates,
                                                    dt,
                                                    "above"
                                                  );
                                                setDepartmentTemplates(updated);
                                              }}
                                            >
                                              Ins ▲
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              className="btn btn-sm btn-outline-secondary"
                                              onClick={() => {
                                                if (!departmentTemplates) {
                                                  return;
                                                }
                                                const updated =
                                                  insertDeptTemplate(
                                                    departmentTemplates,
                                                    dt,
                                                    "below"
                                                  );
                                                setDepartmentTemplates(updated);
                                              }}
                                            >
                                              Ins ▼
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <div>
                                        <div style={{ minWidth: 100 }}>
                                          <input
                                            style={{ minWidth: 100 }}
                                            className="form-control form-control-sm"
                                            defaultValue={
                                              dt.departmentTemplate?.name ?? ""
                                            }
                                            key={`dtname-ord-${
                                              dt.departmentTemplate?.uuid ?? 0
                                            }`}
                                            placeholder="Name..."
                                            onBlur={(e) => {
                                              setDepartmentTemplates(
                                                departmentTemplates?.map(
                                                  (dtx, ix) =>
                                                    dt.departmentTemplate
                                                      ?.ordering ===
                                                      dtx.departmentTemplate
                                                        ?.ordering && dtx
                                                      ? {
                                                          ...dtx,
                                                          departmentTemplate:
                                                            dtx.departmentTemplate
                                                              ? {
                                                                  ...dtx.departmentTemplate,
                                                                  name: e.target
                                                                    .value,
                                                                }
                                                              : dtx.departmentTemplate,
                                                        }
                                                      : dtx
                                                )
                                              );
                                            }}
                                          />
                                        </div>
                                        <div>
                                          {dt.departmentTemplate?.name} ord:
                                          {dt.departmentTemplate?.ordering ?? 0}
                                        </div>
                                        <div className="d-flex w-100">
                                          <div className="w-100">
                                            <Select
                                              options={ctx?.extUsers ?? []}
                                              placeholder="Head..."
                                              getOptionLabel={(u) =>
                                                `${u.username}:${u.name}`
                                              }
                                              value={
                                                dt.departmentTemplate?.extHeadId
                                                  ? ctx?.extUsers.find(
                                                      (u) =>
                                                        `${u.id}` ===
                                                        `${dt.departmentTemplate?.extHeadId}`
                                                    )
                                                  : null
                                              }
                                              onChange={(v) => {
                                                const val =
                                                  v as unknown as ExtUser;

                                                setDepartmentTemplates(
                                                  departmentTemplates?.map(
                                                    (dtx, ix) =>
                                                      dt.departmentTemplate
                                                        ?.ordering ===
                                                        dtx.departmentTemplate
                                                          ?.ordering && dtx
                                                        ? {
                                                            ...dtx,
                                                            departmentTemplate:
                                                              dtx.departmentTemplate
                                                                ? {
                                                                    ...dtx.departmentTemplate,
                                                                    extHeadId:
                                                                      val.id,
                                                                  }
                                                                : dtx.departmentTemplate,
                                                          }
                                                        : dtx
                                                  )
                                                );
                                              }}
                                            />
                                          </div>

                                          {dt.departmentTemplate?.extHeadId ? (
                                            <>
                                              <div>
                                                <button
                                                  className="btn btn-sm btn-danger px-1 py-0"
                                                  onClick={() => {
                                                    setDepartmentTemplates(
                                                      departmentTemplates?.map(
                                                        (dtx, ix) =>
                                                          dt.departmentTemplate
                                                            ?.ordering ===
                                                            dtx
                                                              .departmentTemplate
                                                              ?.ordering && dtx
                                                            ? {
                                                                ...dtx,
                                                                departmentTemplate:
                                                                  dtx.departmentTemplate
                                                                    ? {
                                                                        ...dtx.departmentTemplate,
                                                                        extHeadId:
                                                                          null,
                                                                      }
                                                                    : dtx.departmentTemplate,
                                                              }
                                                            : dtx
                                                      )
                                                    );
                                                  }}
                                                >
                                                  <Delete />
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        <div className="d-flex w-100">
                                          <div className="w-100">
                                            <Select
                                              options={
                                                ctx?.extDepartments ?? []
                                              }
                                              placeholder="Dept..."
                                              getOptionLabel={(d) =>
                                                `${d.name}`
                                              }
                                              value={
                                                dt.departmentTemplate
                                                  ?.extDepartmentId
                                                  ? ctx?.extDepartments.find(
                                                      (d) =>
                                                        `${d.id}` ===
                                                        `${dt.departmentTemplate?.extDepartmentId}`
                                                    )
                                                  : null
                                              }
                                              onChange={(v) => {
                                                const val =
                                                  v as unknown as ExtDepartment;

                                                setDepartmentTemplates(
                                                  departmentTemplates?.map(
                                                    (dtx, ix) =>
                                                      dt.departmentTemplate
                                                        ?.ordering ===
                                                        dtx.departmentTemplate
                                                          ?.ordering && dtx
                                                        ? {
                                                            ...dtx,
                                                            departmentTemplate:
                                                              dtx.departmentTemplate
                                                                ? {
                                                                    ...dtx.departmentTemplate,
                                                                    extDepartmentId:
                                                                      val.id,
                                                                  }
                                                                : dtx.departmentTemplate,
                                                          }
                                                        : dtx
                                                  )
                                                );
                                              }}
                                            />
                                          </div>
                                          {dt.departmentTemplate
                                            ?.extDepartmentId ? (
                                            <>
                                              <div>
                                                <button
                                                  className="btn btn-sm btn-danger px-1 py-0"
                                                  onClick={() => {
                                                    setDepartmentTemplates(
                                                      departmentTemplates?.map(
                                                        (dtx, ix) =>
                                                          dt.departmentTemplate
                                                            ?.ordering ===
                                                            dtx
                                                              .departmentTemplate
                                                              ?.ordering && dtx
                                                            ? {
                                                                ...dtx,
                                                                departmentTemplate:
                                                                  dtx.departmentTemplate
                                                                    ? {
                                                                        ...dtx.departmentTemplate,
                                                                        extDepartmentId:
                                                                          null,
                                                                      }
                                                                    : dtx.departmentTemplate,
                                                              }
                                                            : dtx
                                                      )
                                                    );
                                                  }}
                                                >
                                                  <Delete />
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>{" "}
                                        <div className="d-flex align-items-center">
                                          <div>
                                            <small>
                                              <strong>App:</strong>
                                            </small>
                                          </div>
                                          <div className="flex-grow-1">
                                            {" "}
                                            <Select
                                              options={
                                                apps.current.map((a) => ({
                                                  label: `${a.name ?? ""}`,
                                                  value: a,
                                                })) ?? []
                                              }
                                              placeholder="App..."
                                              value={apps.current
                                                .map((a) => ({
                                                  label: `${a.name ?? ""}`,
                                                  value: a,
                                                }))
                                                .find(
                                                  (a) =>
                                                    `${a.value.id}` ===
                                                    `${dt.departmentTemplate?.extAppId}`
                                                )}
                                              onChange={(v) => {
                                                const val = v as {
                                                  value: ExtApp;
                                                };
                                                if (dt.departmentTemplate) {
                                                  dt.departmentTemplate.extAppId =
                                                    val.value.id;

                                                  render();
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        Project editable?
                                      </div>
                                      <div>
                                        <input
                                          type="checkbox"
                                          checked={
                                            dt.departmentTemplate
                                              ?.projectEditable
                                              ? true
                                              : false
                                          }
                                          onClick={() => {
                                            setDepartmentTemplates(
                                              departmentTemplates?.map(
                                                (dtx, ix) =>
                                                  dt.departmentTemplate
                                                    ?.ordering ===
                                                    dtx.departmentTemplate
                                                      ?.ordering && dtx
                                                    ? {
                                                        ...dtx,
                                                        departmentTemplate:
                                                          dtx.departmentTemplate
                                                            ? {
                                                                ...dtx.departmentTemplate,
                                                                projectEditable:
                                                                  !dtx
                                                                    .departmentTemplate
                                                                    .projectEditable,
                                                              }
                                                            : dtx.departmentTemplate,
                                                      }
                                                    : dtx
                                              )
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        Project category?
                                      </div>
                                      <div>
                                        <input
                                          type="checkbox"
                                          checked={
                                            dt.departmentTemplate?.isProject
                                              ? true
                                              : false
                                          }
                                          onClick={() => {
                                            setDepartmentTemplates(
                                              departmentTemplates?.map(
                                                (dtx, ix) =>
                                                  dt.departmentTemplate
                                                    ?.ordering ===
                                                    dtx.departmentTemplate
                                                      ?.ordering && dtx
                                                    ? {
                                                        ...dtx,
                                                        departmentTemplate:
                                                          dtx.departmentTemplate
                                                            ? {
                                                                ...dtx.departmentTemplate,
                                                                isProject:
                                                                  !dtx
                                                                    .departmentTemplate
                                                                    .isProject,
                                                              }
                                                            : dtx.departmentTemplate,
                                                      }
                                                    : dtx
                                              )
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                {(() => {
                                  const dtis =
                                    dt?.departmentTemplateItems ?? [];

                                  dtis.sort(
                                    (a, b) =>
                                      (a.departmentTemplateItem?.ordering ??
                                        0) -
                                      (b.departmentTemplateItem?.ordering ?? 0)
                                  );

                                  return dtis;
                                })()?.map((it, j) => {
                                  return (
                                    <>
                                      <td className="border border-dark">
                                        <div className="d-flex">
                                          <div className="d-flex align-items-center">
                                            {j + 1}.{" "}
                                            <button
                                              className="btn btn-danger btn-sm"
                                              onClick={() => {
                                                if (departmentTemplates) {
                                                  setDepartmentTemplates(
                                                    departmentTemplates
                                                      ?.filter((dt) => {
                                                        switch (
                                                          deptFilterType
                                                        ) {
                                                          case "All":
                                                            return true;

                                                          case "Project":
                                                            return (
                                                              dt
                                                                .departmentTemplate
                                                                ?.isProject ==
                                                              true
                                                            );

                                                          case "NonProject":
                                                            return !dt
                                                              .departmentTemplate
                                                              ?.isProject;

                                                          default:
                                                            return false;
                                                        }
                                                      })
                                                      .map((dtx, ix) =>
                                                        dtx.departmentTemplate
                                                          ?.uuid ===
                                                        dt.departmentTemplate
                                                          ?.uuid
                                                          ? {
                                                              ...dtx,
                                                              departmentTemplateItems:
                                                                dtx.departmentTemplateItems
                                                                  ? dtx.departmentTemplateItems.filter(
                                                                      (
                                                                        dtix,
                                                                        jx
                                                                      ) =>
                                                                        dtix
                                                                          .departmentTemplateItem
                                                                          ?.uuid !==
                                                                        it
                                                                          .departmentTemplateItem
                                                                          ?.uuid
                                                                    )
                                                                  : dtx.departmentTemplateItems,
                                                            }
                                                          : dtx
                                                      )
                                                  );
                                                  setDepartmentTemplateItemDeleteIds(
                                                    [
                                                      ...departmentTemplateItemDeleteIds,
                                                      it.departmentTemplateItem
                                                        ?.id ?? 0,
                                                    ]
                                                  );
                                                }
                                              }}
                                            >
                                              Delete
                                            </button>
                                            <div>
                                              <input
                                                className="form-control form-control-sm"
                                                placeholder="Name..."
                                                style={{ maxWidth: 100 }}
                                                defaultValue={
                                                  it?.departmentTemplateItem
                                                    ?.name ?? ""
                                                }
                                                key={`dti-${dt.departmentTemplate?.ordering}-${it.departmentTemplateItem?.ordering}`}
                                                onBlur={(e) => {
                                                  if (departmentTemplates) {
                                                    setDepartmentTemplates(
                                                      departmentTemplates.map(
                                                        (dtx, ix) =>
                                                          dtx.departmentTemplate
                                                            ?.uuid ===
                                                          dt.departmentTemplate
                                                            ?.uuid
                                                            ? {
                                                                ...dtx,
                                                                departmentTemplateItems:
                                                                  dtx.departmentTemplateItems
                                                                    ? dtx.departmentTemplateItems.map(
                                                                        (
                                                                          dtix,
                                                                          jx
                                                                        ) =>
                                                                          dtix
                                                                            .departmentTemplateItem
                                                                            ?.uuid &&
                                                                          it
                                                                            .departmentTemplateItem
                                                                            ?.uuid ===
                                                                            dtix
                                                                              .departmentTemplateItem
                                                                              ?.uuid
                                                                            ? {
                                                                                ...dtix,
                                                                                departmentTemplateItem:
                                                                                  dtix.departmentTemplateItem
                                                                                    ? {
                                                                                        ...dtix.departmentTemplateItem,
                                                                                        name: e
                                                                                          .target
                                                                                          .value,
                                                                                      }
                                                                                    : dtix.departmentTemplateItem,
                                                                              }
                                                                            : dtix
                                                                      )
                                                                    : dtx.departmentTemplateItems,
                                                              }
                                                            : dtx
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <small>
                                            {it.departmentTemplateItem?.name}
                                          </small>
                                        </div>

                                        <div className="d-flex w-100">
                                          <div className="w-100">
                                            <Select
                                              options={ctx?.extUsers ?? []}
                                              placeholder="PIC..."
                                              getOptionLabel={(u) =>
                                                `${u.username}:${u.name}`
                                              }
                                              value={
                                                it.departmentTemplateItem
                                                  ?.extUserId
                                                  ? ctx?.extUsers.find(
                                                      (u) =>
                                                        `${u.id}` ===
                                                        `${it.departmentTemplateItem?.extUserId}`
                                                    )
                                                  : null
                                              }
                                              onChange={(v) => {
                                                const val =
                                                  v as unknown as ExtUser;

                                                if (departmentTemplates) {
                                                  setDepartmentTemplates(
                                                    departmentTemplates.map(
                                                      (dtx, ix) =>
                                                        dtx.departmentTemplate
                                                          ?.uuid ===
                                                        dt.departmentTemplate
                                                          ?.uuid
                                                          ? {
                                                              ...dtx,
                                                              departmentTemplateItems:
                                                                dtx.departmentTemplateItems
                                                                  ? dtx.departmentTemplateItems.map(
                                                                      (
                                                                        dtix,
                                                                        jx
                                                                      ) =>
                                                                        dtix
                                                                          .departmentTemplateItem
                                                                          ?.uuid &&
                                                                        it
                                                                          .departmentTemplateItem
                                                                          ?.uuid ===
                                                                          dtix
                                                                            .departmentTemplateItem
                                                                            ?.uuid
                                                                          ? {
                                                                              ...dtix,
                                                                              departmentTemplateItem:
                                                                                dtix.departmentTemplateItem
                                                                                  ? {
                                                                                      ...dtix.departmentTemplateItem,
                                                                                      extUserId:
                                                                                        val.id,
                                                                                    }
                                                                                  : dtix.departmentTemplateItem,
                                                                            }
                                                                          : dtix
                                                                    )
                                                                  : dtx.departmentTemplateItems,
                                                            }
                                                          : dtx
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                          {it.departmentTemplateItem
                                            ?.extUserId ? (
                                            <>
                                              <div>
                                                <button
                                                  className="btn btn-sm btn-danger px-1 py-0"
                                                  onClick={() => {
                                                    if (
                                                      it.departmentTemplateItem
                                                    ) {
                                                      it.departmentTemplateItem.extUserId =
                                                        null;
                                                      render();
                                                    }
                                                  }}
                                                >
                                                  <Delete />{" "}
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>

                                        <div className="d-flex w-100">
                                          <div className="w-100">
                                            <Select
                                              options={
                                                ctx?.extDepartments ?? []
                                              }
                                              placeholder="Dept..."
                                              getOptionLabel={(d) =>
                                                `${d.name}`
                                              }
                                              value={
                                                it.departmentTemplateItem
                                                  ?.extDepartmentId
                                                  ? ctx?.extDepartments.find(
                                                      (d) =>
                                                        `${d.id}` ===
                                                        `${it.departmentTemplateItem?.extDepartmentId}`
                                                    )
                                                  : null
                                              }
                                              onChange={(v) => {
                                                const val =
                                                  v as unknown as ExtDepartment;

                                                if (departmentTemplates) {
                                                  setDepartmentTemplates(
                                                    departmentTemplates.map(
                                                      (dtx, ix) =>
                                                        dtx.departmentTemplate
                                                          ?.uuid ===
                                                        dt.departmentTemplate
                                                          ?.uuid
                                                          ? {
                                                              ...dtx,
                                                              departmentTemplateItems:
                                                                dtx.departmentTemplateItems
                                                                  ? dtx.departmentTemplateItems.map(
                                                                      (
                                                                        dtix,
                                                                        jx
                                                                      ) =>
                                                                        dtix
                                                                          .departmentTemplateItem
                                                                          ?.uuid &&
                                                                        it
                                                                          .departmentTemplateItem
                                                                          ?.uuid ===
                                                                          dtix
                                                                            .departmentTemplateItem
                                                                            ?.uuid
                                                                          ? {
                                                                              ...dtix,
                                                                              departmentTemplateItem:
                                                                                dtix.departmentTemplateItem
                                                                                  ? {
                                                                                      ...dtix.departmentTemplateItem,
                                                                                      extDepartmentId:
                                                                                        val.id,
                                                                                    }
                                                                                  : dtix.departmentTemplateItem,
                                                                            }
                                                                          : dtix
                                                                    )
                                                                  : dtx.departmentTemplateItems,
                                                            }
                                                          : dtx
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                          {it.departmentTemplateItem
                                            ?.extDepartmentId ? (
                                            <>
                                              <div>
                                                <button
                                                  className="btn btn-sm btn-danger px-1 py-0"
                                                  onClick={() => {
                                                    if (
                                                      it.departmentTemplateItem
                                                    ) {
                                                      it.departmentTemplateItem.extDepartmentId =
                                                        null;
                                                      render();
                                                    }
                                                  }}
                                                >
                                                  <Delete />{" "}
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                              <tr>
                                <td></td>
                                <td
                                  className={`${
                                    dt.departmentTemplate?.variable
                                      ? "bg-success"
                                      : "bg-danger"
                                  } text-light font-weight-bold`}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (departmentTemplates) {
                                        setDepartmentTemplates(
                                          departmentTemplates.map((dtx) =>
                                            dtx.departmentTemplate?.uuid ===
                                              dt.departmentTemplate?.uuid && dtx
                                              ? {
                                                  ...dtx,
                                                  departmentTemplate:
                                                    dtx.departmentTemplate
                                                      ? {
                                                          ...dtx.departmentTemplate,
                                                          variable:
                                                            !dtx
                                                              .departmentTemplate
                                                              .variable,
                                                        }
                                                      : dtx.departmentTemplate,
                                                }
                                              : dtx
                                          )
                                        );
                                      }
                                    }}
                                  >
                                    {dt.departmentTemplate?.variable
                                      ? "Variable to Qty"
                                      : "Fixed"}
                                  </div>
                                  <div className="d-flex text-light font-weight-bold justify-content-between">
                                    <div>
                                      Days:{dt.departmentTemplate?.processDays}
                                    </div>
                                    <div>
                                      <input
                                        style={{ maxWidth: 75 }}
                                        className="form-control form-control-sm"
                                        placeholder="Days..."
                                        key={`day-temp=${dt.departmentTemplate?.uuid}`}
                                        defaultValue={
                                          dt.departmentTemplate?.processDays ??
                                          0
                                        }
                                        onBlur={(e) => {
                                          if (departmentTemplates) {
                                            setDepartmentTemplates(
                                              departmentTemplates.map((dtx) =>
                                                dtx.departmentTemplate?.uuid ===
                                                  dt.departmentTemplate?.uuid &&
                                                dtx
                                                  ? {
                                                      ...dtx,
                                                      departmentTemplate:
                                                        dtx.departmentTemplate
                                                          ? {
                                                              ...dtx.departmentTemplate,
                                                              processDays:
                                                                isNaN(
                                                                  parseInt(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                )
                                                                  ? dtx
                                                                      .departmentTemplate
                                                                      .processDays
                                                                  : parseInt(
                                                                      e.target
                                                                        .value
                                                                    ),
                                                            }
                                                          : dtx.departmentTemplate,
                                                    }
                                                  : dtx
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    {/* {(() => {
                                const dts = dt?.departmentTemplateItems ?? [];
      
                                // dts.sort(
                                //   (a, b) =>
                                //     (a.departmentTemplateItem?.ordering ?? 0) -
                                //     (b.departmentTemplateItem?.ordering ?? 0)
                                // );
      
                                return (
                                  <>
                                    {JSON.stringify(
                                      dts.map(
                                        (dt) => dt.departmentTemplateItem?.ordering
                                      )
                                    )}
                                  </>
                                );
                              })()} */}
                                  </div>
                                </td>

                                {(() => {
                                  const dtis =
                                    dt?.departmentTemplateItems ?? [];

                                  dtis.sort(
                                    (a, b) =>
                                      (a.departmentTemplateItem?.ordering ??
                                        0) -
                                      (b.departmentTemplateItem?.ordering ?? 0)
                                  );

                                  return dtis;
                                })()?.map((it, j) => {
                                  return (
                                    <>
                                      {" "}
                                      <td className="border border-dark">
                                        <div
                                          className="overflow-auto"
                                          style={{ width: 200 }}
                                        >
                                          <div>
                                            {selectedDepartmentTemplateItemUuid ? (
                                              <>
                                                {dt.departmentTemplateItems.find(
                                                  (dti) =>
                                                    `${dti.departmentTemplateItem?.uuid}` ===
                                                    selectedDepartmentTemplateItemUuid
                                                ) &&
                                                `${it.departmentTemplateItem?.uuid}` !==
                                                  selectedDepartmentTemplateItemUuid ? (
                                                  <>
                                                    <button
                                                      className="btn btn-outline-success btn-sm"
                                                      onClick={() => {
                                                        const newDeptTemplates =
                                                          [
                                                            ...(departmentTemplates ??
                                                              []),
                                                          ];

                                                        const foundDt =
                                                          newDeptTemplates.find(
                                                            (dt) =>
                                                              dt.departmentTemplateItems.find(
                                                                (dti) =>
                                                                  `${dti.departmentTemplateItem?.uuid}` ===
                                                                  selectedDepartmentTemplateItemUuid
                                                              )
                                                          );

                                                        if (foundDt) {
                                                          const newDepartmentTemplateItems =
                                                            foundDt.departmentTemplateItems;
                                                          const a =
                                                            newDepartmentTemplateItems.find(
                                                              (dtix) =>
                                                                `${dtix.departmentTemplateItem?.uuid}` ===
                                                                `${selectedDepartmentTemplateItemUuid}`
                                                            );

                                                          const b =
                                                            newDepartmentTemplateItems.find(
                                                              (dtix) =>
                                                                `${dtix.departmentTemplateItem?.uuid}` ===
                                                                `${it.departmentTemplateItem?.uuid}`
                                                            );
                                                          const aOrd =
                                                            a
                                                              ?.departmentTemplateItem
                                                              ?.ordering ??
                                                            null;
                                                          const bOrd =
                                                            b
                                                              ?.departmentTemplateItem
                                                              ?.ordering ??
                                                            null;

                                                          setDepartmentTemplates(
                                                            departmentTemplates?.map(
                                                              (dtx) =>
                                                                dtx
                                                                  ? {
                                                                      ...dtx,
                                                                      departmentTemplateItems:
                                                                        dtx.departmentTemplateItems.map(
                                                                          (
                                                                            dtix
                                                                          ) =>
                                                                            dtix
                                                                              ? {
                                                                                  ...dtix,
                                                                                  departmentTemplateItem:
                                                                                    dtix.departmentTemplateItem
                                                                                      ? {
                                                                                          ...dtix.departmentTemplateItem,
                                                                                          ordering:
                                                                                            (() => {
                                                                                              switch (
                                                                                                dtix
                                                                                                  .departmentTemplateItem
                                                                                                  ?.uuid
                                                                                              ) {
                                                                                                case a
                                                                                                  ?.departmentTemplateItem
                                                                                                  ?.uuid:
                                                                                                  return bOrd;
                                                                                                case b
                                                                                                  ?.departmentTemplateItem
                                                                                                  ?.uuid:
                                                                                                  return aOrd;
                                                                                                default:
                                                                                                  return dtix
                                                                                                    .departmentTemplateItem
                                                                                                    .ordering;
                                                                                              }
                                                                                            })(),
                                                                                        }
                                                                                      : dtix.departmentTemplateItem,
                                                                                }
                                                                              : dtix
                                                                        ),
                                                                    }
                                                                  : dtx
                                                            )
                                                          );
                                                          setselectedDepartmentTemplateItemUuid(
                                                            null
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      Here
                                                    </button>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <button
                                                  className="btn btn-sm btn-outline-primary"
                                                  onClick={() => {
                                                    setselectedDepartmentTemplateItemUuid(
                                                      it.departmentTemplateItem
                                                        ?.uuid
                                                    );
                                                  }}
                                                >
                                                  Move
                                                </button>
                                              </>
                                            )}
                                          </div>
                                          <div>
                                            {
                                              it.departmentTemplateItem
                                                ?.ordering
                                            }
                                          </div>
                                          {it.departmentTemplateItem
                                            ?.extRcemSubProcessId ? (
                                            <>
                                              <strong>
                                                {(() => {
                                                  const foundRcem =
                                                    rcemDepartments?.departments
                                                      ?.map(
                                                        (d) => d.processes ?? []
                                                      )
                                                      .flat()
                                                      .map(
                                                        (p) =>
                                                          p.subProcesses ?? []
                                                      )
                                                      .flat()
                                                      .find(
                                                        (sp) =>
                                                          `${sp.masterJavaBaseModel?.id}` ===
                                                          `${it.departmentTemplateItem?.extRcemSubProcessId}`
                                                      );

                                                  const foundRcemDept =
                                                    rcemDepartments?.departments
                                                      .map(
                                                        (d) => d.processes ?? []
                                                      )
                                                      .flat()
                                                      .find(
                                                        (p) =>
                                                          `${p.masterJavaBaseModel?.id}` ===
                                                          `${foundRcem?.ppicRcemProcessId}`
                                                      );

                                                  return `${foundRcem?.name} (${foundRcemDept?.name}) (${foundRcem?.timeProcess} hrs)`;
                                                })()}
                                              </strong>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          <Select
                                            placeholder="Process catalog..."
                                            options={rcemDepartments?.departments
                                              ?.map((d) => d.processes ?? [])
                                              .flat()
                                              .map((p) => p.subProcesses ?? [])
                                              .flat()
                                              .map((sp) => ({
                                                label: sp.name,
                                                value: sp,
                                              }))}
                                            onChange={(v) => {
                                              const val = v as unknown as {
                                                value: PpicRcemSubProcess;
                                              };

                                              setDepartmentTemplates(
                                                departmentTemplates?.map((dt) =>
                                                  dt
                                                    ? {
                                                        ...dt,
                                                        departmentTemplateItems:
                                                          dt.departmentTemplateItems.map(
                                                            (itx) =>
                                                              itx &&
                                                              `${itx.departmentTemplateItem?.id}` ===
                                                                `${it.departmentTemplateItem?.id}`
                                                                ? {
                                                                    ...itx,
                                                                    departmentTemplateItem:
                                                                      itx.departmentTemplateItem
                                                                        ? {
                                                                            ...itx.departmentTemplateItem,
                                                                            extRcemSubProcessId:
                                                                              (() => {
                                                                                return isNaN(
                                                                                  parseInt(
                                                                                    val
                                                                                      .value
                                                                                      .masterJavaBaseModel
                                                                                      ?.id ??
                                                                                      ""
                                                                                  )
                                                                                )
                                                                                  ? itx
                                                                                      .departmentTemplateItem
                                                                                      .extRcemSubProcessId
                                                                                  : parseInt(
                                                                                      val
                                                                                        .value
                                                                                        .masterJavaBaseModel
                                                                                        ?.id ??
                                                                                        ""
                                                                                    );
                                                                              })(),
                                                                          }
                                                                        : itx.departmentTemplateItem,
                                                                  }
                                                                : itx
                                                          ),
                                                      }
                                                    : dt
                                                )
                                              );
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                    </table>
                  </div>
                </>
              );
            case "Preset":
              return (
                <>
                  <div>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        const newDeptTemplatePreset =
                          PpicPanelCodeDepartmentTemplatePreset.fromPartial({
                            masterJavaBaseModel:
                              MasterJavaBaseModel.fromPartial({ uuid: v4() }),
                            items: departmentTemplates
                              ?.map((dt) => dt.departmentTemplateItems)
                              .flat()
                              .map((i) =>
                                PpicPanelCodeDepartmentTemplatePresetDeptItem.fromPartial(
                                  {
                                    masterJavaBaseModel:
                                      MasterJavaBaseModel.fromPartial({
                                        uuid: v4(),
                                      }),
                                    departmentTemplateItemId: i
                                      .departmentTemplateItem?.id
                                      ? `${i.departmentTemplateItem?.id}`
                                      : undefined,
                                  }
                                )
                              ),
                          });

                        setDeptTemplatePresets({
                          ...deptTemplatePresets,
                          presets: deptTemplatePresets.presets
                            ? [
                                ...deptTemplatePresets.presets,
                                newDeptTemplatePreset,
                              ]
                            : [newDeptTemplatePreset],
                        });
                      }}
                    >
                      <Add /> Add Preset
                    </button>
                  </div>
                  <div className="d-flex">
                    Show hidden?{" "}
                    <input
                      type="checkbox"
                      onClick={() => {
                        setShowHidden((s) => !s);
                      }}
                    />
                  </div>
                  <div
                    className="overflow-auto border border-dark shadow shadow-md"
                    style={{ resize: "vertical", height: "75vh" }}
                  >
                    <table
                      className="table table-sm"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {["#", "Name", "Toggle all", "Hide"].map((h) => (
                          <>
                            <th
                              className="bg-dark text-light"
                              style={{ position: "sticky", top: 0 }}
                            >
                              {h}
                            </th>
                          </>
                        ))}
                        {departmentTemplates?.map((dt) => {
                          return dt.departmentTemplateItems.map((ti) => {
                            return (
                              <>
                                <th
                                  style={{
                                    position: "sticky",
                                    top: 0,
                                    backgroundColor: new ColorHash({
                                      lightness: [0.8],
                                    }).hex(dt.departmentTemplate?.name ?? ""),
                                  }}
                                >
                                  <div>
                                    <div>{dt.departmentTemplate?.name}</div>
                                    <div>
                                      <small>
                                        {ti.departmentTemplateItem?.name}
                                      </small>
                                    </div>
                                  </div>
                                </th>
                              </>
                            );
                          });
                        })}
                      </tr>
                      {deptTemplatePresets.presets
                        .filter((p) =>
                          showHidden
                            ? p.masterJavaBaseModel?.hidden
                            : !p.masterJavaBaseModel?.hidden
                        )
                        .map((p, i) => {
                          return (
                            <>
                              <tr>
                                <td className="border border-dark">{i + 1}</td>
                                <td
                                  className="border border-dark bg-light"
                                  style={{
                                    position: "sticky",
                                    left: 0,
                                  }}
                                >
                                  <div style={{ width: 300 }}>
                                    <input
                                      key={`inp-${p.masterJavaBaseModel?.uuid}`}
                                      className="form-control form-control-sm"
                                      placeholder="Name..."
                                      defaultValue={p.name}
                                      onBlur={(e) => {
                                        setDeptTemplatePresets({
                                          ...deptTemplatePresets,
                                          presets:
                                            deptTemplatePresets.presets.filter(
                                              (p) =>
                                                showHidden
                                                  ? p.masterJavaBaseModel
                                                      ?.hidden
                                                  : !p.masterJavaBaseModel
                                                      ?.hidden
                                            )
                                              ? deptTemplatePresets.presets.map(
                                                  (px, ix) =>
                                                    ix === i
                                                      ? {
                                                          ...px,
                                                          name: e.target.value,
                                                        }
                                                      : px
                                                )
                                              : deptTemplatePresets.presets,
                                        });
                                      }}
                                    />
                                  </div>
                                </td>
                                <td className="border border-dark">
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={() => {
                                      if (p.items.length > 0) {
                                        setDeptTemplatePresetItemDeleteIds([
                                          ...deptTemplatePresetItemDeleteIds,
                                          ...p.items.map(
                                            (i) =>
                                              i.masterJavaBaseModel?.id ?? ""
                                          ),
                                        ]);
                                        setDeptTemplatePresets({
                                          ...deptTemplatePresets,
                                          presets: deptTemplatePresets.presets
                                            .filter((p) =>
                                              showHidden
                                                ? p.masterJavaBaseModel?.hidden
                                                : !p.masterJavaBaseModel?.hidden
                                            )
                                            .map((px, ix) =>
                                              ix === i && p
                                                ? {
                                                    ...px,
                                                    items: px.items
                                                      ? []
                                                      : px.items,
                                                  }
                                                : px
                                            ),
                                        });
                                      } else {
                                        setDeptTemplatePresets({
                                          ...deptTemplatePresets,
                                          presets: deptTemplatePresets.presets
                                            .filter((p) =>
                                              showHidden
                                                ? p.masterJavaBaseModel?.hidden
                                                : !p.masterJavaBaseModel?.hidden
                                            )
                                            .map((px, ix) =>
                                              ix === i && p
                                                ? {
                                                    ...px,
                                                    items: px.items
                                                      ? departmentTemplates
                                                          ?.flatMap(
                                                            (t) =>
                                                              t.departmentTemplateItems
                                                          )
                                                          .map((i) => {
                                                            return PpicPanelCodeDepartmentTemplatePresetDeptItem.fromPartial(
                                                              {
                                                                masterJavaBaseModel:
                                                                  MasterJavaBaseModel.fromPartial(
                                                                    {
                                                                      uuid: v4(),
                                                                    }
                                                                  ),
                                                                departmentTemplateItemId:
                                                                  i
                                                                    .departmentTemplateItem
                                                                    ?.id
                                                                    ? `${i.departmentTemplateItem?.id}`
                                                                    : undefined,
                                                              }
                                                            );
                                                          }) ?? px.items
                                                      : px.items,
                                                  }
                                                : px
                                            ),
                                        });
                                      }
                                    }}
                                  >
                                    Toggle all
                                  </button>
                                </td>
                                <td className="border border-dark">
                                  {p.masterJavaBaseModel?.hidden ? (
                                    <button
                                      className="btn btn-outline-success"
                                      onClick={() => {
                                        setDeptTemplatePresets({
                                          ...deptTemplatePresets,
                                          presets:
                                            deptTemplatePresets.presets.map(
                                              (px) =>
                                                px.masterJavaBaseModel?.uuid ===
                                                p.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      masterJavaBaseModel: {
                                                        ...px.masterJavaBaseModel,
                                                        hidden:
                                                          !px
                                                            .masterJavaBaseModel
                                                            ?.hidden,
                                                      },
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    >
                                      <small>Unhide</small>
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-outline-danger"
                                      onClick={() => {
                                        setDeptTemplatePresets({
                                          ...deptTemplatePresets,
                                          presets:
                                            deptTemplatePresets.presets.map(
                                              (px) =>
                                                px.masterJavaBaseModel?.uuid ===
                                                p.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      masterJavaBaseModel: {
                                                        ...px.masterJavaBaseModel,
                                                        hidden:
                                                          !px
                                                            .masterJavaBaseModel
                                                            ?.hidden,
                                                      },
                                                    }
                                                  : px
                                            ),
                                        });
                                      }}
                                    >
                                      <small>Hide</small>
                                    </button>
                                  )}{" "}
                                </td>
                                {departmentTemplates?.map((dt) => {
                                  return dt.departmentTemplateItems.map(
                                    (ti, j) => {
                                      const foundItemInPreset = p.items.find(
                                        (i) =>
                                          `${i.departmentTemplateItemId}` ===
                                          `${ti.departmentTemplateItem?.id}`
                                      );
                                      return (
                                        <>
                                          <td
                                            className={`border border-dark ${
                                              foundItemInPreset
                                                ? `bg-light`
                                                : `bg-dark`
                                            }`}
                                            onClick={() => {
                                              if (
                                                p.items.find(
                                                  (tix) =>
                                                    `${ti.departmentTemplateItem?.id}` ===
                                                    `${tix.departmentTemplateItemId}`
                                                )
                                              ) {
                                                setDeptTemplatePresetItemDeleteIds(
                                                  [
                                                    ...deptTemplatePresetItemDeleteIds,
                                                    `${foundItemInPreset?.masterJavaBaseModel?.id}`,
                                                  ]
                                                );
                                                setDeptTemplatePresets({
                                                  ...deptTemplatePresets,
                                                  presets:
                                                    deptTemplatePresets.presets.map(
                                                      (px, ix) =>
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid && p
                                                          ? {
                                                              ...px,
                                                              items: px.items
                                                                ? px.items.filter(
                                                                    (tix) =>
                                                                      `${tix.departmentTemplateItemId}` !==
                                                                      `${ti.departmentTemplateItem?.id}`
                                                                  )
                                                                : px.items,
                                                            }
                                                          : px
                                                    ),
                                                });
                                              } else {
                                                const newPresetItem =
                                                  PpicPanelCodeDepartmentTemplatePresetDeptItem.fromPartial(
                                                    {
                                                      masterJavaBaseModel:
                                                        MasterJavaBaseModel.fromPartial(
                                                          { uuid: v4() }
                                                        ),
                                                      departmentTemplateItemId:
                                                        ti
                                                          .departmentTemplateItem
                                                          ?.id
                                                          ? `${ti.departmentTemplateItem?.id}`
                                                          : undefined,
                                                    }
                                                  );

                                                setDeptTemplatePresets({
                                                  ...deptTemplatePresets,
                                                  presets:
                                                    deptTemplatePresets.presets
                                                      ? deptTemplatePresets.presets.map(
                                                          (px, ix) =>
                                                            px
                                                              .masterJavaBaseModel
                                                              ?.uuid ===
                                                            p
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                              ? {
                                                                  ...px,
                                                                  items:
                                                                    px.items
                                                                      ? [
                                                                          ...px.items,
                                                                          newPresetItem,
                                                                        ]
                                                                      : [
                                                                          newPresetItem,
                                                                        ],
                                                                }
                                                              : px
                                                        )
                                                      : deptTemplatePresets.presets,
                                                });
                                              }
                                            }}
                                          >
                                            <button className="btn btn-sm btn-outline-primary">
                                              Toggle
                                            </button>
                                          </td>
                                        </>
                                      );
                                    }
                                  );
                                })}{" "}
                              </tr>
                            </>
                          );
                        })}
                    </table>
                  </div>
                </>
              );

            case "Template Group":
              return (
                <>
                  <div>
                    <div>Template Group</div>
                    <div>
                      <hr />
                    </div>
                    <div>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          templateGroups.current = [
                            ...templateGroups.current,
                            {
                              ...initialDepartmentTemplateGroup,
                              uuid: v4(),
                              category: selectedTemplateCategories.current,
                              departmentTemplateGroupItems: [],
                            },
                          ];

                          render();
                        }}
                      >
                        Add
                      </button>
                    </div>

                    <ol>
                      {templateGroups.current
                        .filter((g) =>
                          selectedTemplateCategories.current
                            ? `${g.category?.id}` ===
                              `${selectedTemplateCategories?.current?.id}`
                            : !g.category
                        )
                        .map((g) => {
                          return (
                            <>
                              <li>
                                <div>
                                  <div>
                                    <input
                                      className="form-control form-control-sm"
                                      placeholder="Group name..."
                                      onBlur={(e) => {
                                        g.name = e.target.value;
                                        render();
                                      }}
                                      defaultValue={g?.name ?? ""}
                                    />
                                  </div>
                                  <div>
                                    <div>
                                      <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                          g.departmentTemplateGroupItems = [
                                            ...(g.departmentTemplateGroupItems ??
                                              []),
                                            {
                                              ...initialDepartmentTemplateGroupItem,

                                              uuid: v4(),
                                            },
                                          ];

                                          render();
                                        }}
                                      >
                                        Add Item
                                      </button>
                                    </div>
                                    <table
                                      className="table table-sm"
                                      style={{ borderCollapse: "separate" }}
                                    >
                                      {[
                                        "#",
                                        "Department Template",
                                        "Action",
                                      ].map((t) => {
                                        return (
                                          <>
                                            <th className="bg-dark text-light">
                                              {t}
                                            </th>
                                          </>
                                        );
                                      })}
                                      {g.departmentTemplateGroupItems
                                        ?.filter((i) => !i.hidden)
                                        ?.map((i, i_) => {
                                          return (
                                            <>
                                              <tr>
                                                <td className="border border-dark">
                                                  {i_ + 1}
                                                </td>
                                                <td className="border border-dark">
                                                  <Select
                                                    options={departmentTemplates?.map(
                                                      (t) => ({
                                                        label:
                                                          t.departmentTemplate
                                                            ?.name ?? "",
                                                        value: t,
                                                      })
                                                    )}
                                                    value={departmentTemplates
                                                      ?.map((t) => ({
                                                        label:
                                                          t.departmentTemplate
                                                            ?.name ?? "",
                                                        value: t,
                                                      }))
                                                      .find(
                                                        (t) =>
                                                          `${t.value.departmentTemplate?.id}` ===
                                                          `${i.departmentTemplate?.id}`
                                                      )}
                                                    onChange={(v) => {
                                                      const val = v as {
                                                        value: DepartmentTemplateView;
                                                      };

                                                      i.departmentTemplate = {
                                                        ...initialDepartmentTemplate,
                                                        id:
                                                          val.value
                                                            .departmentTemplate
                                                            ?.id ?? null,
                                                      };
                                                    }}
                                                  />
                                                </td>
                                                <td className="border border-dark">
                                                  <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                      i.hidden = true;
                                                      render();
                                                    }}
                                                  >
                                                    Delete
                                                  </button>
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                    </table>
                                  </div>
                                  <div>
                                    <hr className="border border-dark" />
                                  </div>
                                </div>
                              </li>
                            </>
                          );
                        })}
                    </ol>
                  </div>
                </>
              );

            case "Template Category":
              return (
                <>
                  <div>
                    <div>
                      <div>
                        <h4>Dept Template Category</h4>
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          templateCategories.current.push({
                            ...initialTemplateCategory,
                            uuid: v4(),
                          });

                          render();
                        }}
                      >
                        Add
                      </button>
                    </div>
                    <div className="border border-dark">
                      <table
                        className="table table-sm"
                        style={{ borderCollapse: "separate" }}
                      >
                        <tr>
                          {" "}
                          {["#", "Name"].map((h) => {
                            return (
                              <>
                                <th className="bg-dark text-light">{h} </th>
                              </>
                            );
                          })}
                        </tr>
                        {templateCategories.current.map((c, i) => {
                          return (
                            <>
                              <tr>
                                <td className="border border-dark">{i + 1}</td>
                                <td className="border border-dark">
                                  <input
                                    className="form-control form-control-sm"
                                    placeholder="Name..."
                                    defaultValue={c.name ?? ""}
                                    onBlur={(e) => {
                                      c.name = e.target.value;
                                      render();
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </>
              );

            default:
              return <></>;
          }
        })()}
      </div>
    </>
  );
};

export default DepartmentTemplatePage;
