import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import {
  CrmPurchaseOrders,
  PpicJob_PpicJobDeptCategory,
  ppicJob_PpicJobDeptCategoryFromJSON,
  ppicJob_PpicJobDeptCategoryToJSON,
  PpicJobs,
} from "../../../masterbigsystem";
import {
  fetchExtCrmPurchaseOrdersProto,
  fetchExtCrmPurchaseOrdersProtoSimple,
  fetchJobsProtoSimple,
  intlFormat,
} from "../../../helpers";
import { render } from "@testing-library/react";
import Select from "react-select";

const JobScreenPage = () => {
  const ctx = useContext(AppContext);

  const jobsProtoSimple = useRef({} as PpicJobs);
  const [, refresh] = useState(false);
  const pos = useRef({} as CrmPurchaseOrders);
  const statusIsClosed = useRef(false as boolean | null);
  const selectedJobCategory = useRef(
    PpicJob_PpicJobDeptCategory.PPC as PpicJob_PpicJobDeptCategory | null
  );

  const render = () => {
    refresh((n) => !n);
  };

  const fetchjobsProtoSimpleDate = async () => {
    const d = await fetchJobsProtoSimple({
      all: true,
      apiKey: ctx?.apiKey ?? "",
      withProducts: true,
      withPurchaseOrders: true,
    });

    if (d) {
      d.jobs.reverse();
    }

    if (d) {
      jobsProtoSimple.current = d;
      render();
    }
  };

  const fetchPosData = async () => {
    const d = await fetchExtCrmPurchaseOrdersProtoSimple({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
      render();
    }
  };

  const handleInit = () => {
    fetchjobsProtoSimpleDate();
    fetchPosData();
  };

  useEffect(() => {
    handleInit();
  }, []);

  return (
    <>
      <div>
        <div>
          <div>
            <h4>Job Screen</h4>
          </div>
        </div>
        <hr />
        <div className="d-flex">
          <div>
            <div>
              <small>
                <strong>Status</strong>
              </small>
            </div>
            <div style={{ width: 200 }}>
              <Select
                value={[
                  { label: "All", value: null },
                  { label: "Closed", value: true },
                  { label: "Open", value: false },
                ].find((d) => d.value === statusIsClosed.current)}
                options={[
                  { label: "All", value: null },
                  { label: "Closed", value: true },
                  { label: "Open", value: false },
                ]}
                onChange={(v) => {
                  const val = v as { value: boolean | null };

                  statusIsClosed.current = val.value;
                  render();
                }}
              />
            </div>
          </div>

          <div>
            <div>
              <small>
                <strong>Job Category</strong>
              </small>
            </div>
            <div style={{ width: 200 }}>
              <Select
                value={[
                  { label: "PPC", value: PpicJob_PpicJobDeptCategory.PPC },
                  {
                    label: "Internal",
                    value: PpicJob_PpicJobDeptCategory.INTERNAL,
                  },
                  {
                    label: "Others",
                    value: PpicJob_PpicJobDeptCategory.OTHERS,
                  },
                  { label: "All", value: null },
                ].find((d) => d.value === selectedJobCategory.current)}
                options={[
                  { label: "PPC", value: PpicJob_PpicJobDeptCategory.PPC },
                  {
                    label: "Internal",
                    value: PpicJob_PpicJobDeptCategory.INTERNAL,
                  },
                  {
                    label: "Others",
                    value: PpicJob_PpicJobDeptCategory.OTHERS,
                  },
                  { label: "All", value: null },
                ]}
                onChange={(v) => {
                  const val = v as {
                    value: PpicJob_PpicJobDeptCategory | null;
                  };

                  selectedJobCategory.current = val.value;
                  render();
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="overflow-auto border border-dark rounded rounded-lg shadow shadow-md"
          style={{ resize: "vertical", height: "80vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {[
                "#",
                "Customer",
                "Job",
                "Type",
                "Panel Code",
                "Panel Type",
                "Qty Total",
                "Qty Done",
                "Qty Outs",
                "Plan FAT/Inspeksi",
                "Plan Delivery",
                "Deadline QC",
                "Deadline Prod",
                "Status",
                "Problem",
              ].map((h) => {
                return (
                  <>
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                );
              })}
            </tr>
            {jobsProtoSimple.current.jobs
              ?.map((j) => j.panelCodes.map((c) => ({ job: j, panelCode: c })))
              ?.flat()
              .filter((d) => {
                return (
                  (statusIsClosed.current !== null
                    ? statusIsClosed.current === true
                      ? d.job.closingDate
                      : !d.job.closingDate
                    : true) &&
                  (selectedJobCategory.current !== null
                    ? selectedJobCategory.current ===
                      ppicJob_PpicJobDeptCategoryFromJSON(d.job.category)
                    : true)
                );
              })
              .map((d, i) => {
                const foundPOs = pos.current.purchaseOrders.filter((p) =>
                  d.job.jobPurchaseOrders.find(
                    (px) => `${px.extPurchaseOrderId}` === `${p.id}`
                  )
                );

                const qtyDone = d.panelCode.closed;

                return (
                  <>
                    <tr>
                      <td className="border border-dark">{i + 1}</td>
                      <td className="border border-dark">
                        {[
                          ...new Set(foundPOs.map((p) => p.account?.name)),
                        ].join(", ")}
                      </td>
                      <td className="border border-dark">{d.job.name}</td>
                      <td className="border border-dark">
                        {ppicJob_PpicJobDeptCategoryToJSON(
                          ppicJob_PpicJobDeptCategoryFromJSON(d.job.category)
                        )}
                      </td>

                      <td className="border border-dark">
                        {d.panelCode?.type}
                      </td>
                      <td className="border border-dark">
                        {d.panelCode?.name}
                      </td>
                      <td className="border border-dark">{d.panelCode?.qty}</td>
                      <td
                        className={`border border-dark text-light ${
                          d.panelCode.closed ? `bg-success` : `bg-danger`
                        }`}
                      >
                        {d.panelCode.closed ? d.panelCode?.qty : 0}
                      </td>
                      <td className="border border-dark">
                        {" "}
                        {d.panelCode.closed ? 0 : d.panelCode?.qty}
                      </td>

                      <td className="border border-dark">
                        {intlFormat({
                          date: d.job.fatDeadline,
                          dateStyle: "medium",
                        })}{" "}
                      </td>
                      <td className="border border-dark">
                        {intlFormat({
                          date: d.job.deliveryDeadline,
                          dateStyle: "medium",
                        })}{" "}
                      </td>
                      <td className="border border-dark">
                        {intlFormat({
                          date: d.job.deadline,
                          dateStyle: "medium",
                        })}
                      </td>
                      <td className="border border-dark">
                        {intlFormat({
                          date: d.job.productionDeadline,
                          dateStyle: "medium",
                        })}{" "}
                      </td>
                      <td className="border border-dark">
                        {d.job?.jobProgressType}
                      </td>
                      <td className="border border-dark"></td>
                    </tr>
                  </>
                );
              })}
          </table>
        </div>
      </div>
    </>
  );
};

export default JobScreenPage;
