import {
  Add,
  CloudDownload,
  Delete,
  Edit,
  Print,
  Search,
} from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { AppContext } from "../../App";
import {
  ExtPurchaseRequestPostBody,
  fetchDetailedPr,
  fetchDetailedPrUrl,
  fetchExtCrmPurchaseOrdersProto,
  fetchExtInventory,
  fetchExtPurchaseRequests,
  fetchItems,
  fetchJob,
  fetchJobBom,
  fetchJobsProtoSimple,
  fetchPrePrs,
  fetchPrItemDetail,
  getItemFullDescription,
  intlFormat,
  makeDateString,
  parseJwt,
  parsePurchaseRequestStatus,
} from "../../helpers";
import {
  CrmPurchaseOrders,
  PpicBomLeveled,
  PpicJob,
  PpicJobs,
  PrePr_PrePrStatus,
  prePr_PrePrStatusFromJSON,
  prePr_PrePrStatusToJSON,
  PrePrs,
} from "../../masterbigsystem";
import {
  ExtInventory,
  ExtItem,
  ExtPurchaseRequest,
  ExtPurchaseRequestPostBodyJson,
  ExtUser,
  PrItemDetail,
  PurchaseRequestDetail,
} from "../../models/model";
import {
  initialExtPurchaseRequestPostBody,
  initialExtPurchaseRequestPostBodyItemJson,
  initialExtPurchaseRequestPostBodyJson,
} from "../../models/modelinitials";
import { RequestStatus } from "../../models/RequestStatus";
import { v4 } from "uuid";
import { Link } from "react-router-dom";
import DetailedReportTable from "./DetailReportSection";
import { DateInput } from "../ZGlobalComponents/DateInput";
import { MyButton } from "../ZGlobalComponents/MyButton";

const PurchaseRequestPage = () => {
  const [extPurchaseRequests, setExtPurchaseRequests] = useState<
    ExtPurchaseRequest[]
  >([]);

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [searchItem, setSearchItem] = useState("");

  const [items, setItems] = useState<ExtItem[]>([]);
  const [searchPR, setSearchPR] = useState("");
  const [newPr, setNewPr] = useState<ExtPurchaseRequestPostBodyJson>({
    ...initialExtPurchaseRequestPostBodyJson,
  });
  const [purchaseRequestItems, setPurchaseRequestItems] = useState<
    { item: ExtItem; qty: number; ea: string }[]
  >([]);
  const [prItemsDetail, setPrItemsDetail] = useState<PrItemDetail[]>([]);
  const ctx = useContext(AppContext);
  const [showSelectItemModal, setShowSelectItemModal] = useState(false);
  const [inventory, setInventory] = useState<ExtInventory[]>([]);
  const [jobs, setJobs] = useState<PpicJobs>(
    PpicJobs.fromPartial({ jobs: [] })
  );

  const [inventoryLoading, setInventoryLoading] = useState(false);
  const from = useRef(makeDateString(new Date()));
  const to = useRef(makeDateString(new Date()));

  const selectedJobBomLeveled = useRef(PpicBomLeveled.fromPartial({}));
  const getJobBomLoading = useRef(false);

  const allPr = useRef([] as ExtPurchaseRequest[]);
  const allPrItemDetail = useRef([] as PrItemDetail[]);
  const allPrLoading = useRef(false);
  const prDetailed = useRef([] as PurchaseRequestDetail[]);
  const prDetailDateBegin = useRef(makeDateString(new Date()));
  const prDetailDateEnd = useRef(makeDateString(new Date()));
  const prDetailDateStatus = useRef("All");
  const prDetailJob = useRef(null as PpicJob | null);
  const [, refresh] = useState(false);

  // const selectedPrStatus = useRef("All");
  const [selectedStatus, setSelectedStatus] = useState<{
    label: string;
    value: number | null;
  }>({ label: "All", value: null });
  const [selectedJobStatus, setSelectedJobStatus] = useState<{
    label: string;
    value: number | null;
  }>({ label: "All", value: null });
  const pos = useRef(CrmPurchaseOrders.fromPartial({ purchaseOrders: [] }));

  const render = () => {
    refresh((n) => !n);
  };

  useEffect(() => {
    handleInit();
  }, []);
  const selectedPR = useRef(null as ExtPurchaseRequest | null);
  const prePrs = useRef(PrePrs.fromPartial({}));

  const fetchPrePrsData = async () => {
    const d = await fetchPrePrs({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      prePrs.current = d;
      render();
    }
  };

  const handleInit = async () => {
    setRequestStatus(RequestStatus.Loading);

    fetchData();
    fetchItemsData();
    fetchInventoryData();
    fetchJobsData();
    fetchExtPurchaseOrdersData();
    fetchPrePrsData();
    fetchExtPurchaseRequestDetails();
  };

  const fetchExtPurchaseOrdersData = async () => {
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
    }

    render();
  };

  const fetchExtPurchaseRequestDetails = async () => {
    const d = await fetchDetailedPr({
      apiKey: ctx?.apiKey ?? "",
      dateBegin: prDetailDateBegin.current,
      dateEnd: prDetailDateEnd.current,
      status: prDetailDateStatus.current,
    });

    if (d) {
      prDetailed.current = d;

      render();
    }
  };

  const fetchItemsData = async () => {
    const itemsData = await fetchItems(ctx?.baseUrl ?? "", ctx?.apiKey ?? "");

    setItems(itemsData ?? items);
  };
  const fetchInventoryData = async () => {
    setInventoryLoading(true);
    setInventory(await fetchExtInventory({ apiKey: ctx?.apiKey ?? "" }));
    setInventoryLoading(false);
  };
  const fetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({
      apiKey: ctx?.apiKey ?? "",
      withPurchaseOrders: true,
      all: true,
    });

    if (d) {
      setJobs(d);
    }
  };

  const bomLeveledData = useRef(
    [] as {
      child: PpicBomLeveled;
      foundInv: ExtInventory[];
      inStock: number;
      inPR: number;
      toPR: number;
      foundItem: ExtItem | undefined;
      uuid: string;
    }[]
  );

  const fetchData = async () => {
    try {
      setExtPurchaseRequests(
        await fetchExtPurchaseRequests({
          apiKey: ctx?.apiKey ?? "",
          from: from.current,
          to: to.current,
        })
      );
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.error(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const fetchAllPrByJob = async (params: { jobId: any }) => {
    try {
      allPrLoading.current = true;
      render();
      const d = await fetchExtPurchaseRequests({
        apiKey: ctx?.apiKey ?? "",
      });

      return d.filter((d) => `${d.jobId}` === `${params.jobId}`);
    } catch (e) {
      return [];
    }
  };

  const handleSavev0 = async () => {
    try {
      console.log(newPr);

      const fd = new FormData();

      for (const key in newPr) {
        fd.append(key, (newPr as any)[key] as any);
      }

      purchaseRequestItems.forEach((purchaseRequestItem) => {
        fd.append("product_id[]", `${purchaseRequestItem.item.id}`);
        fd.append("mfr[]", `${purchaseRequestItem.item.mfr}`);
        fd.append("part_num[]", `${purchaseRequestItem.item.partNum}`);
        fd.append("part_name[]", `${purchaseRequestItem.item.partName}`);
        fd.append("part_desc[]", `${purchaseRequestItem.item.partDesc}`);
        fd.append("curr[]", `RP`);
        fd.append("unit_cost[]", `0`);
        fd.append("qty_pr[]", `${purchaseRequestItem.qty}`);
        fd.append("um_pr[]", purchaseRequestItem.ea);

        // "product_id[]": foundItem.id,
        // "mfr[]": foundItem.mfr,
        // "part_num[]": foundItem.partNum,
        // "part_name[]": foundItem.partName,
        // "part_desc[]": foundItem.partDesc,
        // "curr[]": "RP",
        // "unit_cost[]": 0,
        // "qty_pr[]": 0,
        // "um_pr[]": "EA",
      });

      const resp = await fetch(
        `${process.env.REACT_APP_WAREHOUSE_URL}/api/save-pr`,
        {
          method: "post",
          body: fd,
        }
      );

      if (resp.status !== 200) throw await resp.text();

      setShowModal(false);
      fetchData();
    } catch (e) {
      console.error(e);
      alert(e);
    }
  };
  const handleSave = async () => {
    try {
      setLoadingSave(true);
      console.log(newPr);

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/ext-purchase-requests-save-json`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...newPr,
            extPurchaseRequestPostBodyItemsJson: purchaseRequestItems.map(
              (pi) => {
                return {
                  ...initialExtPurchaseRequestPostBodyItemJson,
                  productId: pi.item.id ?? 0,
                  qtyPr: pi.qty ?? 0,
                };
              }
            ),
          } as ExtPurchaseRequestPostBodyJson),
        }
      );

      selectedJobBomLeveled.current = PpicBomLeveled.fromPartial({});
      // fetchAllPr();

      render();

      handleInit();
    } catch (e) {
      console.error(e);
      alert(e);
    } finally {
      setShowModal(false);
      setLoadingSave(false);
    }
  };

  type ViewModeType = "PR" | "PrePR" | "Detailed Report";
  const viewModeTypes = ["PR", "PrePR", "Detailed Report"] as ViewModeType[];

  const viewMode = useRef("PR" as ViewModeType);

  return (
    <div>
      <div className="d-flex align-items-center">
        <div>
          <h3>Purchase Request</h3>
        </div>

        <div className="mx-2">
          <MyButton
            onClick={() => {
              setShowModal(true);
              setNewPr({
                ...initialExtPurchaseRequestPostBodyJson,
                createdBy:
                  (parseJwt(ctx?.apiKey ? ctx.apiKey : "") as any)?.sub ?? "",
                prNumber: `PR/PPIC/${new Date().getFullYear()}/${
                  new Date().getMonth() + 1 < 10
                    ? `0${new Date().getMonth() + 1}`
                    : `${new Date().getMonth() + 1}`
                }/${
                  new Date().getDate() < 10
                    ? `0${new Date().getDate()}`
                    : `${new Date().getDate()}`
                }/`,
              });
            }}
            variant="primary"
            label={
              <>
                {" "}
                <Add /> Add
              </>
            }
          />
        </div>
        {requestStatus === RequestStatus.Loading ? (
          <Spinner animation="border" />
        ) : (
          <></>
        )}
        {inventoryLoading ? (
          <>
            <Spinner animation="border" /> Inventory Loading...
          </>
        ) : (
          <></>
        )}
      </div>
      <hr className="border border-dark" />

      <div className="d-flex my-2">
        <strong>View mode:</strong>
        {viewModeTypes.map((t) => {
          return (
            <>
              <div className="mx-2">
                <MyButton
                  label={t}
                  onClick={() => {
                    viewMode.current = t;
                    render();
                  }}
                  active={t === viewMode.current}
                  size="sm"
                  variant={
                    t === viewMode.current ? "primary" : "outline-primary"
                  }
                  // className="btn btn-sm"
                />
                {/* <button
                  onClick={() => {
                    viewMode.current = t;
                    render();
                  }}
                  className={`btn btn-sm px-1 py-0 ${t === viewMode.current
                    ? `btn-primary`
                    : `btn-outline-primary`
                    }`}
                >
                  {t}
                </button> */}
              </div>
            </>
          );
        })}
      </div>

      {viewMode.current === "PR" ? (
        <>
          {" "}
          <div>
            <div>
              <div className="d-flex mb-2 align-items-end">
                <div className="mr-2">
                  <div>
                    <small>
                      <strong>From</strong>
                    </small>
                  </div>
                  <div>
                    <DateInput
                      value={from.current}
                      endDate={to.current}
                      onChange={(value) => {
                        setRequestStatus(RequestStatus.Loading);

                        from.current = value;
                        render();
                        fetchData();
                      }}
                    />

                    {/* <input
                      className="form-control form-control-sm"
                      type="date"
                      defaultValue={makeDateString(new Date())}
                      onBlur={(e) => {
                        setRequestStatus(RequestStatus.Loading);

                        from.current = e.target.value;
                        render();
                        fetchData();
                      }}
                    /> */}
                  </div>
                </div>

                <div className="mr-2">
                  <div>
                    <small>
                      <strong>To </strong>
                    </small>
                  </div>
                  <div>
                    <DateInput
                      value={to.current}
                      startDate={from.current}
                      onChange={(value) => {
                        setRequestStatus(RequestStatus.Loading);
                        to.current = value;
                        render();
                        fetchData();
                      }}
                    />
                    {/* <input
                      className="form-control form-control-sm"
                      type="date"
                      defaultValue={makeDateString(new Date())}
                      onBlur={(e) => {
                        setRequestStatus(RequestStatus.Loading);

                        to.current = e.target.value;
                        render();
                        fetchData();
                      }}
                    /> */}
                  </div>
                </div>
                <div style={{ width: 250 }} className="mr-2">
                  <div>
                    <small>
                      <strong>Status </strong>
                    </small>
                  </div>
                  <Select
                    options={[
                      { label: "All", value: null },
                      { label: "Outstanding", value: 0 },
                      { label: "Approved", value: 1 },
                      // { label: "Onprogress", value: "Onprogress" },
                      // {label: 'Outstanding',value:'Outstanding'},
                    ]}
                    placeholder="Select status..."
                    value={selectedStatus}
                    onChange={(v) => {
                      const val = v as { label: string; value: number | null };

                      setSelectedStatus(val);
                      render();
                      // setPurchaseRequestItems([
                      //   ...purchaseRequestItems,
                      //   { item: val, qty: 0, ea: "" },
                      // ]);
                    }}
                  />
                </div>
                <div style={{ width: 250 }} className="mr-2">
                  <div>
                    <small>
                      <strong>Job Status </strong>
                    </small>
                  </div>
                  <Select
                    options={[
                      { label: "All", value: null },
                      { label: "Outstanding", value: 0 },
                      { label: "Closed", value: 1 },
                    ]}
                    placeholder="Select status..."
                    value={selectedJobStatus}
                    onChange={(v) => {
                      const val = v as { label: string; value: number | null };

                      setSelectedJobStatus(val);
                      render();
                      // setPurchaseRequestItems([
                      //   ...purchaseRequestItems,
                      //   { item: val, qty: 0, ea: "" },
                      // ]);
                    }}
                  />
                </div>

                <div className="mr-2">
                  <a
                    href={`${
                      process.env.REACT_APP_BASE_URL
                    }/ext-purchase-requests?from=${from.current ?? ""}&to=${
                      to.current ?? ""
                    }&excel=true`}
                    target="_blank"
                    className="text-success"
                  >
                    <MyButton
                      variant="success"
                      label={
                        <>
                          {" "}
                          <CloudDownload /> XLSX
                        </>
                      }
                    />
                  </a>
                </div>

                <div className="mr-2">
                  <a
                    href={`${
                      process.env.REACT_APP_BASE_URL
                    }/ext-purchase-requests-report?from=${
                      from.current ?? ""
                      // }&to=${to.current ?? ""}&status=${selectedPrStatus.current}`}
                    }&to=${to.current ?? ""}&status=Onprogress`}
                    target="_blank"
                    className="text-success"
                  >
                    <MyButton
                      variant="success"
                      label={
                        <>
                          {" "}
                          <CloudDownload /> New Format XLSX
                        </>
                      }
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="flex-grow-1">
                {" "}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSearchPR(searchPR);
                  }}
                >
                  <input
                    placeholder="Search by number, purpose"
                    className="form-control form-control-sm"
                    defaultValue={searchPR}
                    onBlur={(e) => setSearchPR(e.target.value)}
                  />
                  <button type="submit">
                    <Search /> Search
                  </button>
                </form>
              </div>
            </div>

            <div
              className="my-2 overflow-auto shadow shadow-lg"
              style={{
                height: "65vh",
                resize: "vertical",
                border: "1px solid gray",
              }}
            >
              <table
                className="table table-sm table-hover"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "#",
                    "PR Number",
                    "Request From",
                    "Purpose",
                    "PO",
                    "Status",
                    "Target",
                    "Created",
                    `Approved (${
                      extPurchaseRequests.filter(
                        (extPurchaseRequest) =>
                          extPurchaseRequest.approved === 1
                      ).length
                    }/${extPurchaseRequests.length})`,
                    `Job`,
                    "Details",
                    "Print",
                    "XLSX",
                  ].map((head) => (
                    <th
                      className="bg-info text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {head}
                    </th>
                  ))}
                </tr>
                {extPurchaseRequests
                  .filter((extPurchaseRequest) => {
                    const matchesSearch =
                      `${extPurchaseRequest.prNumber}${extPurchaseRequest.prNumberSeq}${extPurchaseRequest.purpose}${extPurchaseRequest.purposeRemark}`
                        .toLowerCase()
                        .includes(searchPR.toLowerCase());
                    const matchesStatus =
                      selectedStatus.value === null ||
                      extPurchaseRequest.approved === selectedStatus.value;
                    const matchesJobStatus = (
                      extPurchaseRequest: ExtPurchaseRequest,
                      selectedJobStatus: { value: number | null },
                      jobs: PpicJobs
                    ) => {
                      const job = jobs.jobs.find(
                        (j) =>
                          `${j.masterJavaBaseModel?.id}` ===
                          `${extPurchaseRequest.jobId}`
                      );
                      if (!job) return false;

                      const closingDate = job.closingDate;
                      // console.log("jobId", job.masterJavaBaseModel?.id, closingDate);

                      if (selectedJobStatus.value === null) return true;
                      if (selectedJobStatus.value === 1)
                        return (
                          closingDate !== null &&
                          closingDate !== "" &&
                          closingDate !== undefined
                        );

                      return (
                        closingDate === null ||
                        closingDate === "" ||
                        closingDate === undefined
                      );
                    };

                    const jobMatch = matchesJobStatus(
                      extPurchaseRequest,
                      selectedJobStatus,
                      jobs
                    );

                    console.log(jobMatch);

                    return matchesSearch && matchesStatus && jobMatch;
                  })
                  .map((extPurchaseRequest, i) => {
                    const foundJob = jobs.jobs.find(
                      (j) =>
                        `${j.masterJavaBaseModel?.id}` ===
                        `${extPurchaseRequest?.jobId}`
                    );
                    const foundPO = pos.current.purchaseOrders.filter((p) =>
                      foundJob?.jobPurchaseOrders.find(
                        (jp) => `${jp.extPurchaseOrderId}` === `${p.id}`
                      )
                    );

                    return (
                      <tr>
                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">
                          {extPurchaseRequest.prNumber}
                          {extPurchaseRequest.prNumberSeq}
                        </td>
                        <td className="border border-dark">
                          {extPurchaseRequest.requestFrom}
                        </td>
                        <td className="border border-dark">
                          <a>
                            {extPurchaseRequest.purpose ?? ""} |{" "}
                            {extPurchaseRequest.purposeRemark ?? ""}
                          </a>
                        </td>
                        <td className="border border-dark">
                          {foundPO
                            .map(
                              (p) =>
                                `${p.purchaseOrderNumber} (${p.account?.name})`
                            )
                            .join(", ")}
                        </td>
                        <td className="border border-dark">
                          {extPurchaseRequest.status} (
                          {
                            parsePurchaseRequestStatus(
                              extPurchaseRequest.status
                            )?.label
                          }
                          )
                        </td>
                        <td className="border border-dark">
                          {(() => {
                            try {
                              return `${Intl.DateTimeFormat("en-US", {
                                dateStyle: "medium",
                                // timeZone: "utc",
                              } as any).format(
                                new Date(extPurchaseRequest.prTarget ?? "")
                              )}`;
                            } catch (e) {
                              return null;
                            }
                          })()}
                        </td>

                        <td className="border border-dark">
                          {(() => {
                            try {
                              return `${Intl.DateTimeFormat("en-US", {
                                dateStyle: "medium",
                                timeZone: "utc",
                              } as any).format(
                                new Date(extPurchaseRequest.createdAt ?? "")
                              )} by ${
                                extPurchaseRequest.createdBy ?? "unknown"
                              }`;
                            } catch (e) {
                              return null;
                            }
                          })()}
                        </td>

                        <td
                          className={`border border-dark font-weight-bold ${
                            extPurchaseRequest.approved === 1
                              ? `bg-success text-light`
                              : `bg-danger`
                          }`}
                        >
                          {extPurchaseRequest.approved === 1 ? (
                            <>
                              {(() => {
                                try {
                                  return `${Intl.DateTimeFormat("en-US", {
                                    // timeStyle: "long",
                                    dateStyle: "medium",
                                    timeZone: "utc",
                                  } as any).format(
                                    new Date(
                                      extPurchaseRequest.approvedDate ?? ""
                                    )
                                  )}`;
                                } catch (e) {
                                  return null;
                                }
                              })()}{" "}
                              by {extPurchaseRequest.approvedBy}{" "}
                            </>
                          ) : (
                            <>None</>
                          )}
                        </td>
                        <td className="border border-dark">
                          {
                            jobs.jobs.find(
                              (j) =>
                                `${j.masterJavaBaseModel?.id}` ===
                                `${extPurchaseRequest.jobId}`
                            )?.name
                          }
                        </td>
                        <td className="border border-dark">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={async () => {
                              setShowDetailsModal(true);
                              selectedPR.current = extPurchaseRequest;
                              render();

                              try {
                                const resp = await fetch(
                                  `${process.env.REACT_APP_BASE_URL}/ext-purchase-request-items/${extPurchaseRequest.id}`
                                );

                                if (resp.status !== 200)
                                  throw await resp.text();

                                const body = await resp.json();

                                console.log("[PR ITEMS DETAIL]", body);
                                setPrItemsDetail(body);
                              } catch (e) {
                                console.error(e);
                              }
                            }}
                          >
                            Details
                          </button>
                        </td>
                        <td className="border border-dark">
                          <a
                            href={`#/purchaserequests/print/${extPurchaseRequest.id}`}
                            target="_blank"
                          >
                            <Print />{" "}
                          </a>
                        </td>
                        <td className="border border-dark">
                          <a
                            href={`${process.env.REACT_APP_BASE_URL}/ext-purchase-requests?id=${extPurchaseRequest.id}&excel=true`}
                            target="_blank"
                            className="text-success"
                          >
                            <CloudDownload />{" "}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {viewMode.current === "PrePR" ? (
        <>
          <div
            className="border border-dark overflow-auto mt-2"
            style={{ height: `75vh`, resize: "vertical" }}
          >
            <table
              className="table table-sm "
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {[
                  "#",
                  "Created Date",
                  "Number",
                  "Requested By",
                  "Approved By",
                  "Approval Status",
                  "Items",
                  "Action",
                ].map((h) => {
                  return (
                    <>
                      <th
                        className="bg-dark text-light p-0.5 m-0"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    </>
                  );
                })}
              </tr>
              {(() => {
                const is = [...prePrs.current.prePrs];
                is.reverse();

                return is;
              })().map((p, i) => {
                return (
                  <>
                    <tr>
                      <td className="border border-dark p-0 m-0">{i + 1}</td>
                      <td className="border border-dark p-0 m-0">
                        {intlFormat({
                          date: p.masterJavaBaseModel?.createdAt,
                          dateStyle: "medium",
                          timeStyle: "short",
                        })}
                      </td>

                      <td className="border border-dark p-0 m-0">
                        PREPR/
                        {new Date(
                          p.masterJavaBaseModel?.createdAt ?? ""
                        ).getFullYear()}
                        /{p.masterJavaBaseModel?.id}
                      </td>
                      <td className="border border-dark p-0 m-0">
                        {
                          ctx?.extUsers.find(
                            (u) =>
                              `${u.id}` ===
                              `${p.masterJavaBaseModel?.extCreatedById}`
                          )?.name
                        }
                      </td>
                      <td className="border border-dark p-0 m-0">
                        {ctx?.extUsers.find(
                          (u) => `${u.id}` === `${p.approvedById}`
                        )?.name ?? "-"}
                      </td>

                      <td className="border border-dark p-0 m-0">
                        <strong
                          className={`${
                            prePr_PrePrStatusFromJSON(p.prePrStatus) ===
                            PrePr_PrePrStatus.PREPR_APPROVED
                              ? `text-success`
                              : `text-danger`
                          }`}
                        >
                          {p.prePrStatus
                            ? prePr_PrePrStatusToJSON(p.prePrStatus)
                            : prePr_PrePrStatusToJSON(
                                PrePr_PrePrStatus.PREPR_OUTSTANDING
                              )}
                        </strong>
                      </td>
                      <td className="border border-dark p-0 m-0">
                        {p.items.length}
                      </td>

                      <td className="border border-dark p-0 m-0">
                        <Link to={`/preprs/${p.masterJavaBaseModel?.id}`}>
                          <button className="btn btn-sm btn-primary px-1 py-0">
                            <Edit /> Edit
                          </button>
                        </Link>
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        </>
      ) : (
        <></>
      )}

      {viewMode.current === "Detailed Report" ? (
        <>
          <div>
            <div>
              <div className="d-flex align-items-end mb-2">
                <div className="mr-2">
                  <div>
                    <small>
                      <strong>From</strong>
                    </small>
                  </div>
                  <div>
                    <DateInput
                      value={prDetailDateBegin.current}
                      endDate={prDetailDateEnd.current}
                      onChange={(value) => {
                        prDetailDateBegin.current = value;
                        render();
                        fetchExtPurchaseRequestDetails();
                      }}
                    />
                  </div>
                </div>

                <div className="mr-2">
                  <div>
                    <small>
                      <strong>To </strong>
                    </small>
                  </div>
                  <div>
                    <DateInput
                      value={prDetailDateEnd.current}
                      startDate={prDetailDateBegin.current}
                      onChange={(value) => {
                        prDetailDateEnd.current = value;
                        render();
                        fetchExtPurchaseRequestDetails();
                      }}
                      // className="form-control form-control-sm"
                    />
                    {/* <input
                      className="form-control form-control-sm"
                      type="date"
                      defaultValue={makeDateString(new Date())}
                      onBlur={(e) => {
                        prDetailDateEnd.current = e.target.value;
                        render();
                        fetchExtPurchaseRequestDetails();
                      }}
                    /> */}
                  </div>
                </div>

                <div className="mr-2">
                  <div>
                    <small>
                      <strong>Status</strong>
                    </small>
                  </div>
                  <div style={{ width: 200 }}>
                    <Select
                      options={[
                        { label: "Outstanding", value: "Outstanding" },
                        { label: "All", value: "All" },
                      ]}
                      placeholder="Status..."
                      onChange={(v) => {
                        const val = v as { value: string };

                        prDetailDateStatus.current = val.value;
                        render();
                        fetchExtPurchaseRequestDetails();
                      }}
                    />
                  </div>
                </div>

                <div className="mr-2">
                  <div>
                    <small>
                      <strong>Job Status</strong>
                    </small>
                  </div>
                  <div style={{ width: 200 }}>
                    <Select
                      options={[
                        { label: "All", value: null },
                        { label: "Outstanding", value: 0 },
                        { label: "Closed", value: 1 },
                      ]}
                      placeholder="Select status..."
                      value={selectedJobStatus}
                      onChange={(v) => {
                        const val = v as {
                          label: string;
                          value: number | null;
                        };

                        setSelectedJobStatus(val);
                        render();
                      }}
                    />
                  </div>
                </div>

                <div className="mr-2">
                  <div className="d-flex">
                    <small>
                      <strong>Job</strong>
                    </small>
                    {/* {prDetailJob.current ? (
                      <button
                        className="btn btn-sm btn-primary px-1 py-0"
                        onClick={() => {
                          prDetailJob.current = null;
                          render();
                        }}
                      >
                        <small>Clear Job Filter</small>
                      </button>
                    ) : null} */}
                  </div>
                  <div style={{ width: 300 }}>
                    <Select
                      isClearable
                      options={jobs.jobs.map((j) => ({
                        label: j.name,
                        value: j,
                      }))}
                      placeholder="Job..."
                      onChange={(v) => {
                        const val = v as { value: PpicJob } | null;
                        prDetailJob.current = val ? val.value : null;
                        render();
                      }}
                    />
                  </div>
                </div>

                <div className="mr-2">
                  <a
                    href={`${fetchDetailedPrUrl({
                      dateBegin: prDetailDateBegin.current,
                      dateEnd: prDetailDateEnd.current,
                      status: prDetailDateStatus.current,
                      extJobId: prDetailJob.current?.masterJavaBaseModel?.id,
                      excel: true,
                      jobStatus: selectedJobStatus.value,
                    })}`}
                  >
                    <MyButton
                      variant="success"
                      label={
                        <>
                          <CloudDownload /> XLSX
                        </>
                      }
                    />
                    {/* <button className="btn btn-sm btn-success">

                    </button> */}
                  </a>
                </div>
              </div>
            </div>
            <DetailedReportTable
              data={prDetailed.current}
              jobs={jobs}
              prDetailJob={prDetailJob.current}
              selectedJobStatus={selectedJobStatus.value}
            />
            {/* <div
              className="border border-dark overflow-auto"
              style={{ height: "75vh", resize: "vertical" }}
            >
              <table className="table table-sm table-bordered">
                <thead className="bg-dark text-light">
                  <tr>
                    {[
                      "#",
                      "Job",
                      "PR Number",
                      "PR Date",
                      "Request From",
                      "Purpose",
                      "PR Target",
                      "PO#",
                      "PO Date",
                      "MFR",
                      "Part Num",
                      "Part Desc",
                      "Qty PR",
                      "Qty PO",
                      "Qty RR",
                      "Qty Balance",
                      "RR Number",
                      "RR Date",
                      "Target Date",
                    ].map((h) => (
                      <th key={h} style={{ position: "sticky", top: 0 , width: h === "PO#" ? "100px" : "auto"}}>
                        {h}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {prDetailed.current
                    .filter((p) =>
                      prDetailJob.current
                        ? `${p.jobId}` ===
                          `${prDetailJob.current.masterJavaBaseModel?.id}`
                        : true
                    )
                    .map((p, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {
                            jobs.jobs.find(
                              (j) =>
                                `${j?.masterJavaBaseModel?.id}` === `${p?.jobId}`
                            )?.name
                          }
                        </td>
                        <td>
                          {p.prNumber}
                          {p.prNumberSeq}
                        </td>
                        <td>
                          {intlFormat({
                            date: p.prDate,
                            dateStyle: "medium",
                          })}
                        </td>
                        <td>{p.requestFrom}</td>
                        <td>{p.purpose}</td>
                        <td>
                          {intlFormat({
                            date: p.prTarget,
                            dateStyle: "medium",
                          })}
                        </td>
                        <td>
                          {p.poNumber}
                          {p.poNumberSeq}
                        </td>
                        <td>
                          {intlFormat({
                            date: p.poDate,
                            dateStyle: "medium",
                          })}
                        </td>
                        <td>{p.mfr}</td>
                        <td>{p.partNum}</td>
                        <td>{p.partDesc}</td>
                        <td>{p.qtyPr}</td>
                        <td>{p.qtyPos}</td>
                        <td>{p.qtyRr}</td>
                        <td>{p.qtyBalance}</td>
                        <td>{p.rrNum}</td>
                        <td>
                          {intlFormat({
                            date: p.rrDate,
                            dateStyle: "medium",
                          })}
                        </td>
                        <td
                          className={
                            p.targetDateNew === p.prTarget ? "bg-dark text-light" : ""
                          }
                        >
                          {intlFormat({
                            date: p.targetDateNew,
                            dateStyle: "medium",
                          })}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div> */}
          </div>
        </>
      ) : (
        <></>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <div className="p-3">
          <div>
            <h3>PR Detail</h3>
          </div>

          <div className="d-flex">
            <div className="flex-grow-1">
              PR Number
              <input
                className="form-control form-control-sm"
                disabled
                value={newPr.prNumber ?? ""}
              />
            </div>
            <div className="flex-grow-1">
              Created By
              <input
                className="form-control form-control-sm"
                disabled
                value={newPr.createdBy ?? ""}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-1">
              Request From: {newPr.requestFrom}
              <Select
                options={ctx?.extUsers ?? []}
                placeholder="Request from..."
                getOptionLabel={(u) => `${u.username}:${u.name}`}
                onChange={(v) => {
                  const val = v as unknown as ExtUser;

                  setNewPr({ ...newPr, requestFrom: val.name });
                }}
              />
              {/* <input
                className="form-control form-control-sm"
                defaultValue={newPr.requestFrom ?? ""}
                placeholder="Request from..."
                onBlur={(e) => {
                  setNewPr({ ...newPr, requestFrom: e.target.value });
                }}
              /> */}
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-1">
              Purpose
              <input
                className="w-100 form-control form-control-sm"
                placeholder="Remark"
                defaultValue={newPr.purpose ?? ""}
                onBlur={(e) => {
                  setNewPr({ ...newPr, purpose: e.target.value });
                }}
              />
            </div>
            <div className="flex-grow-1">
              Purpose Remark
              <input
                className="w-100 form-control form-control-sm"
                placeholder="Purpose"
                defaultValue={newPr.purposeRemark ?? ""}
                onBlur={(e) => {
                  setNewPr({ ...newPr, purposeRemark: e.target.value });
                }}
              />
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div>
            <div>
              <small>For Job</small>
            </div>
            <div>
              <Select
                options={jobs.jobs ?? []}
                getOptionLabel={(j) => `${j.name}`}
                onChange={async (v) => {
                  const val = v as unknown as PpicJob;

                  // Fetch job and job PR data detailed

                  getJobBomLoading.current = true;
                  allPrLoading.current = true;

                  render();

                  const j = await fetchJobBom({
                    id: isNaN(parseInt(val.masterJavaBaseModel?.id ?? ""))
                      ? 0
                      : parseInt(val.masterJavaBaseModel?.id ?? ""),
                    apiKey: ctx?.apiKey ?? "",
                  });
                  if (j) {
                    selectedJobBomLeveled.current = j;

                    bomLeveledData.current =
                      selectedJobBomLeveled.current.children.map((c, i) => {
                        const foundInv = inventory.filter(
                          (i) => `${i.productId}` === `${c.extItemId}`
                        );

                        const inStock = foundInv.reduce(
                          (acc, i) => acc + (i.balanceRsv ?? 0),
                          0
                        );
                        const inPR = allPrItemDetail.current
                          .filter((i) => `${i.productId}` === `${c.extItemId}`)
                          .reduce((acc, d) => acc + (d.qtyPr ?? 0), 0);

                        const toPR =
                          (c.qty ?? 0) - (c.inMr ?? 0) - inStock - inPR;

                        const foundItem = items.find(
                          (i) => `${i.id}` === `${c.extItemId}`
                        );

                        return {
                          child: c,
                          foundInv,
                          inStock,
                          inPR,
                          toPR,
                          foundItem,
                          uuid: v4(),
                        };
                      });
                  }

                  getJobBomLoading.current = false;

                  const relevantPrs = await fetchAllPrByJob({
                    jobId: val.masterJavaBaseModel?.id,
                  });

                  // Fetch all PR item details
                  const d = (
                    await Promise.all(
                      relevantPrs.map(async (p) => {
                        try {
                          const d = await fetchPrItemDetail({ prId: p.id });

                          return d;
                        } catch (e) {
                          return [];
                        }
                      })
                    )
                  ).flat();

                  console.log(
                    "relevant prs",
                    relevantPrs,
                    j?.masterJavaBaseModel?.id
                  );
                  console.log("pr item detail", d);

                  // Set in pr item details
                  allPrItemDetail.current = d;
                  allPrLoading.current = false;
                  render();

                  setNewPr({
                    ...newPr,
                    jobId: val.masterJavaBaseModel?.id
                      ? isNaN(parseInt(val.masterJavaBaseModel?.id ?? ""))
                        ? newPr.jobId
                        : parseInt(val.masterJavaBaseModel?.id ?? "")
                      : newPr.jobId,
                  });

                  render();
                }}
              />
            </div>
          </div>
          {getJobBomLoading.current ||
          inventoryLoading ||
          allPrLoading.current ? (
            <>
              {}
              {getJobBomLoading.current ? (
                <>
                  <Spinner animation="border" />
                  Load BOM...
                </>
              ) : (
                <></>
              )}
              {inventoryLoading ? (
                <>
                  <Spinner animation="border" />
                  Load inv...
                </>
              ) : (
                <></>
              )}
              {allPrLoading.current ? (
                <>
                  <Spinner animation="border" />
                  Load all PR...
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {selectedJobBomLeveled.current ? (
                (() => {
                  return (
                    <>
                      <div className="d-flex justify-content-end w-100">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            if (
                              window.confirm(
                                `Create PR for these items?\n${bomLeveledData.current
                                  .filter((d) => d.toPR > 0)
                                  .map(
                                    (d) =>
                                      `- ${getItemFullDescription(
                                        d.foundItem
                                      )}: x${d.toPR}`
                                  )
                                  .join("\n")}`
                              )
                            ) {
                              setPurchaseRequestItems([
                                ...purchaseRequestItems,
                                ...bomLeveledData.current
                                  .filter((d) => d.toPR > 0 && d.foundItem)
                                  .map(
                                    (d) =>
                                      ({
                                        item: d.foundItem,
                                        qty: d.toPR,
                                        ea: d.foundItem?.defaultUm,
                                      } as {
                                        item: ExtItem;
                                        qty: number;
                                        ea: string;
                                      })
                                  ),
                              ]);
                            }
                          }}
                        >
                          Create Auto PR
                        </button>
                      </div>
                      <div
                        className="overflow-auto"
                        style={{ height: "35vh", resize: "vertical" }}
                      >
                        <table
                          className="table table-sm"
                          style={{ borderCollapse: "separate" }}
                        >
                          <tr>
                            {[
                              "#",
                              "Item",
                              "Qty",
                              "In MR (Job)",
                              "In Stock",
                              "In PR (Job)",
                              "qty PR",
                              // "qty PR sel.",
                            ].map((h) => (
                              <>
                                <th
                                  className="bg-dark text-light p-0 m-0"
                                  style={{ position: "sticky", top: 0 }}
                                >
                                  {h}
                                </th>
                              </>
                            ))}
                          </tr>
                          {bomLeveledData.current.map((c, i) => {
                            return (
                              <>
                                <tr>
                                  <td className="border border-dark p-0 m-0">
                                    {i + 1}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    <small>
                                      {getItemFullDescription(c.foundItem)}
                                    </small>
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {c.child.qty?.toFixed(1)}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {c.child.inMr}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {c.inStock.toFixed(1)}
                                  </td>
                                  <td className="border border-dark p-0 m-0">
                                    {c.inPR}
                                  </td>
                                  <td
                                    className={`border border-dark p-0 m-0 ${
                                      c.toPR <= 0 ? `bg-success` : `bg-warning`
                                    }`}
                                  >
                                    <div className="d-flex">
                                      <input
                                        className="form-control form-control-sm px-1"
                                        style={{ width: 50 }}
                                        placeholder="Qty..."
                                        defaultValue={c.toPR < 0 ? 0 : c.toPR}
                                        onBlur={(e) => {
                                          if (
                                            isNaN(parseFloat(e.target.value))
                                          ) {
                                            return;
                                          }

                                          c.toPR = parseFloat(e.target.value);
                                          render();
                                        }}
                                        key={`pr-q-${c.uuid}`}
                                      />
                                      <strong>
                                        {c.toPR <= 0 ? `` : c.toPR.toFixed(1)}
                                      </strong>
                                    </div>
                                  </td>
                                  {/* <td className="border border-dark p-0 m-0">
                                    {c.toPR < 0 ? (
                                      <></>
                                    ) : (
                                      <>
                                        <input
                                          placeholder="Qty.."
                                          className="form-control form-control-sm p-0 m-0"
                                          style={{ width: 75 }}
                                          key={`pr-item-${c.child.extItemId}`}
                                          defaultValue={c.toPR}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              isNaN(parseFloat(e.target.value))
                                            ) {
                                              return;
                                            }
                                          }}
                                        />
                                      </>
                                    )}
                                  </td> */}
                                </tr>
                              </>
                            );
                          })}
                        </table>
                      </div>
                    </>
                  );
                })()
              ) : (
                <></>
              )}{" "}
            </>
          )}
          <div>
            <hr />
          </div>
          <div>Item</div>
          <div className="d-flex">
            <div>
              <div>Date</div>
              <div>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  onBlur={(e) => {
                    setNewPr({
                      ...newPr,
                      prTarget: new Date(
                        `${makeDateString(
                          new Date(e.target.value)
                        )}T00:00:00.000Z`
                      )
                        .toISOString()
                        .split("T")[0],
                    });
                  }}
                  defaultValue={newPr.prTarget ?? ""}
                />
              </div>
            </div>
          </div>
          {/* <div>
            <input
              className="form-control form-control-sm"
              // placeholder="Search item..."
              defaultValue={searchItem}
              onBlur={(e) => {
                setSearchItem(e.target.value);
              }}
            />
            <Select
              options={items}
              placeholder="Select item..."
              onChange={(v) => {
                const val = v as unknown as ExtItem;

                setPurchaseRequestItems([
                  ...purchaseRequestItems,
                  { item: val, qty: 0, ea: "" },
                ]);
              }}
            />
          </div> */}
          <div>
            <MyButton
              variant="primary"
              label={
                <>
                  <Add /> Add
                </>
              }
              onClick={() => {
                setShowSelectItemModal(true);
              }}
            />
            {/* <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setShowSelectItemModal(true);
              }}
            >
              <Add /> Add
            </button> */}
          </div>
          {/* <div>
            <select
              onInput={(e) => {
                // alert((e.target as any)?.value);

                const foundItem = items.find(
                  (item) => `${item.id}` === (e.target as any)?.value
                );

                if (foundItem) {
                  setNewPr({
                    ...newPr,
                    // "product_id[]": foundItem.id,
                    // "mfr[]": foundItem.mfr,
                    // "part_num[]": foundItem.partNum,
                    // "part_name[]": foundItem.partName,
                    // "part_desc[]": foundItem.partDesc,
                    // "curr[]": "RP",
                    // "unit_cost[]": 0,
                    // "qty_pr[]": 0,
                    // "um_pr[]": "EA",
                  });
                  setPurchaseRequestItems([
                    ...purchaseRequestItems,
                    { item: { ...foundItem }, qty: 0, ea: "" },
                  ]);
                }
              }}
              className="form-control form-control-sm"
            >
              <option selected disabled></option>
              <option></option>
              {(() => {
                const filteredItems = items.filter((item) =>
                  `${item.partNum}${item.mfr}${item.partName}${item.partDesc}`
                    .toLowerCase()
                    .includes(searchItem.toLowerCase())
                );

                return filteredItems.map((item, i) => (
                  <option value={`${item.id}`}>
                    #{filteredItems.length - i}) {item.partNum} ({item.mfr}):{" "}
                    {item.partName}, {item.partDesc}
                  </option>
                ));
              })()}
            </select>
          </div> */}
          {/* {newPr["product_id[]"] ? (
            <div>
              <div>
                <strong>
                  Selected: {newPr["part_num[]"]} ({newPr["mfr[]"]}):{" "}
                  {newPr["part_name[]"]}
                </strong>
              </div>
              <div>
                <small>{newPr["part_desc[]"]}</small>
              </div>
            </div>
          ) : (
            <></>
          )} */}
          <hr />
          <div className="my-2">
            <ol>
              {purchaseRequestItems.map((purchaseRequestItem, i) => {
                return (
                  <li>
                    <div className="d-flex justify-content-between">
                      <div>
                        {purchaseRequestItem.item.partNum} (
                        {purchaseRequestItem.item.mfr}){" "}
                        {purchaseRequestItem.item.partName}:{" "}
                        {purchaseRequestItem.item.partDesc}
                      </div>
                      <div className="d-flex">
                        <input
                          style={{ width: 75 }}
                          type="number"
                          className="form-control"
                          placeholder="Qty"
                          onBlur={(e) => {
                            setPurchaseRequestItems(
                              purchaseRequestItems.map(
                                (purchaseRequestItemX, ix) =>
                                  ix === i
                                    ? {
                                        ...purchaseRequestItemX,
                                        qty: isNaN(parseFloat(e.target.value))
                                          ? purchaseRequestItemX.qty
                                          : parseFloat(e.target.value),
                                      }
                                    : purchaseRequestItemX
                              )
                            );
                          }}
                        />
                        {purchaseRequestItem.qty}

                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            setPurchaseRequestItems(
                              purchaseRequestItems.filter((_, ix) => ix !== i)
                            );
                          }}
                        >
                          <Delete />{" "}
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
          <hr />
          <div className="d-flex justify-content-end">
            {loadingSave ? (
              <>
                <Spinner animation="border" />
              </>
            ) : (
              <>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        size="xl"
        show={showSelectItemModal}
        onHide={() => setShowSelectItemModal(false)}
      >
        <div className="m-4 ">
          <div>
            <input
              className="form-control form-control-sm"
              placeholder="Search item..."
              onBlur={(e) => {
                setSearchItem(e.target.value);
              }}
            />
          </div>
          <div>
            <hr />
          </div>
          <div
            style={{ height: "35vh" }}
            className="overflow-auto border border-dark"
          >
            <table className="table table-sm">
              {items
                .filter((i) => {
                  const compiledName =
                    `${i.partNum}${i.partName}${i.partDesc}`.toLowerCase();

                  return (
                    searchItem !== "" &&
                    searchItem
                      .split(" ")
                      .map((s) => compiledName.includes(s.toLowerCase()))
                      .filter((s) => s).length === searchItem.split(" ").length
                  );
                })
                .map((i, i_) => {
                  const inventories = inventory.filter(
                    (inv) => `${inv.products?.id}` === `${i.id}`
                  );

                  return (
                    <>
                      <tr>
                        <td className="border border-dark p-0 m-0">{i_ + 1}</td>
                        <td className="border border-dark p-0 m-0">
                          {i.partNum}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <small>{getItemFullDescription(i)}</small>
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <small>
                            <ol>
                              {inventories.map((inv) => {
                                return (
                                  <>
                                    <li>
                                      <div>
                                        <div>
                                          {inv.warehouse?.warehouse?.name}.
                                          {inv.warehouse?.whName}: {inv.qty}
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>
                          </small>
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              // setShowSelectItemModal(false);
                              setPurchaseRequestItems([
                                ...purchaseRequestItems,
                                { item: i, qty: 0, ea: "" },
                              ]);
                            }}
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </table>
          </div>
          <div
            style={{ height: "35vh" }}
            className="overflow-auto border border-dark"
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {["#", "Item", "Qty", "UM"].map((h) => (
                  <>
                    <th className="bg-dark text-light p-0 m-0">{h}</th>
                  </>
                ))}
              </tr>
              {purchaseRequestItems.map((i, i_) => {
                const foundItem = items.find(
                  (ix) => `${ix.id}` === `${i.item.id}`
                );
                return (
                  <>
                    <tr>
                      <td className="border border-dark p-0 m-0">{i_ + 1}</td>
                      <td className="border border-dark p-0 m-0">
                        {getItemFullDescription(foundItem)}
                      </td>
                      <td className="border border-dark p-0 m-0">
                        <input
                          className="form-control form-control-sm"
                          style={{ width: 75 }}
                          placeholder="Qty..."
                          key={`pr-item-${i_}`}
                          defaultValue={i.qty}
                          onBlur={(e) => {
                            const num = parseFloat(e.target.value);
                            if (isNaN(num)) {
                              return;
                            }

                            setPurchaseRequestItems((pri) =>
                              pri.map((ix, ix_) =>
                                ix && ix?.item?.id === i?.item?.id
                                  ? { ...ix, qty: num }
                                  : ix
                              )
                            );
                          }}
                        />
                        {/* {i.qty} */}
                      </td>
                      <td className="border border-dark p-0 m-0">
                        {i.item.defaultUm}
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        </div>
      </Modal>
      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        size="xl"
      >
        <div className="p-3">
          <div>
            <h3>PR Detail</h3>
          </div>

          <div>
            {selectedPR.current?.approved === 1 ? (
              <></>
            ) : (
              <>
                {" "}
                <div className="d-flex">
                  <div>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={async () => {
                        if (
                          !window.confirm(
                            "Delete PR? This action cannot be undone."
                          )
                        ) {
                          return;
                        }

                        const resp = await fetch(
                          `${process.env.REACT_APP_BASE_URL}/ext-purchase-requests-delete/${selectedPR.current?.id}`
                        );

                        setShowDetailsModal(false);
                        handleInit();
                      }}
                    >
                      Cancel PR
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={async () => {
                        if (!window.confirm("Update PR?")) {
                          return;
                        }

                        const resp = await fetch(
                          `${process.env.REACT_APP_BASE_URL}/ext-purchase-requests-update-json/${selectedPR.current?.id}`,
                          {
                            method: "post",
                            headers: { "content-type": "application/json" },
                            body: JSON.stringify({
                              ...selectedPR.current,
                              extPurchaseRequestPostBodyItemsJson:
                                prItemsDetail.map((i) => {
                                  const prItemId = i?.id;

                                  let prItem = {
                                    ...i,
                                    ...i.item,
                                    curr: "IDR",
                                    unitCost: 0.0,
                                    jobId: selectedPR.current?.jobId
                                      ? selectedPR.current.jobId
                                      : 0,
                                    umPr: i.item?.defaultUm
                                      ? i.item.defaultUm
                                      : "EA",
                                  };

                                  prItem.id = prItemId;

                                  return prItem;
                                }),
                            }),
                          }
                        );

                        setShowDetailsModal(false);
                        handleInit();
                      }}
                    >
                      Update PR
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <hr />
          </div>
          <div>
            <ol>
              {prItemsDetail.map((prItem) => {
                return (
                  <li>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        {prItem.item?.partNum} ({prItem.item?.mfr}){" "}
                        {prItem.item?.partName}: {prItem.item?.partDesc}
                      </div>
                      <div className="text-right d-flex">
                        <div>
                          <input
                            key={`pr-${selectedPR.current?.id}-${prItem.productId}`}
                            defaultValue={prItem.qtyPr ?? 0}
                            className="form-control form-control-sm"
                            onBlur={(e) => {
                              setPrItemsDetail(
                                prItemsDetail.map((px) =>
                                  px.productId === prItem.productId
                                    ? {
                                        ...px,
                                        qtyPr: isNaN(parseFloat(e.target.value))
                                          ? 0
                                          : parseFloat(e.target.value),
                                      }
                                    : px
                                )
                              );
                            }}
                          />
                        </div>
                        <strong>
                          {/* {prItem.qtyPr}  */}
                          {prItem.qtyPr} {prItem.item?.defaultUm}
                        </strong>
                      </div>
                    </div>
                    <hr className="border border-dark" />
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PurchaseRequestPage;
