import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Delete, Edit, Print } from "@material-ui/icons";
import { AppContext } from "../../../App";
import {
  fetchJobsProtoSimple,
  fetchNcrGeneralsProto,
  intlFormat,
} from "../../../helpers";
import {
  PpicJobs,
  PpicNcrGeneralProblemCorrectiveAction,
  PpicNcrGenerals,
} from "../../../masterbigsystem";

/**
 * NcrListPage
 * Display a list of NCRGeneral records in a table, with link to detail page.
 */
const NcrGeneralPage = () => {
  const ctx = useContext(AppContext);
  const [ncrs, setNcrs] = useState<PpicNcrGenerals>(
    PpicNcrGenerals.fromPartial({ ncrGenerals: [] })
  );
  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const jobs = useRef(PpicJobs.fromPartial({}));
  const fetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({
      apiKey: ctx?.apiKey ?? "",

      all: true,
    });

    if (d) {
      jobs.current = d;
    }
    render();
  };

  useEffect(() => {
    initData();
    fetchJobsData();
  }, []);

  const initData = async () => {
    try {
      const data = await fetchNcrGeneralsProto({ apiKey: ctx?.apiKey ?? "" });
      if (data) {
        data.ncrGenerals?.reverse();

        setNcrs(data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = async (id?: string | number) => {
    // if (!id) return;
    // if (!window.confirm("Delete this NCR?")) return;
    // try {
    //   await deleteNcrGeneral(id, ctx.apiKey ?? "");
    //   initData(); // refresh list
    // } catch (e) {
    //   console.error(e);
    //   alert("Error deleting NCR");
    // }
  };

  return (
    <div>
      <div className="d-flex">
        <h4>NCR List</h4>
        <div className="mx-2">
          <Link to="/ncrgeneral/new">
            <button className="btn btn-primary btn-sm">Add</button>
          </Link>
        </div>
      </div>

      <hr className="border border-dark" />

      <div
        className="overflow-auto border border-dark"
        style={{ height: "75vh" }}
      >
        <table className="table table-sm">
          <thead>
            <tr>
              <th className="bg-dark text-light">Action</th>
              <th className="bg-dark text-light">#</th>
              <th className="bg-dark text-light">Date</th>
              <th className="bg-dark text-light">Job</th>

              <th className="bg-dark text-light">Issuer</th>
              <th className="bg-dark text-light">NC Area</th>
              <th className="bg-dark text-light">Root Cause</th>
              <th className="bg-dark text-light">Corrective Actions</th>
              <th className="bg-dark text-light">Preventive Actions</th>
            </tr>
          </thead>
          <tbody>
            {ncrs?.ncrGenerals?.map((ncr, idx) => {
              return (
                <tr key={ncr.masterJavaBaseModel?.id ?? idx}>
                  <td className="border border-dark">
                    <div className="d-flex">
                      <div>
                        <Link to={`/ncrgeneral/${ncr.masterJavaBaseModel?.id}`}>
                          <button className="btn btn-primary btn-sm px-1 py-0">
                            <Edit />
                          </button>
                        </Link>
                      </div>
                      <div className="mx-2">
                        <Link
                          target="_blank"
                          to={`/ncrgeneral/${ncr.masterJavaBaseModel?.id}/print`}
                        >
                          <button className="btn btn-primary btn-sm px-1 py-0">
                            <Print />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td className="border border-dark">{idx + 1}</td>
                 

                  <td className="border border-dark">
                    <Link to={`/ncrgeneral/${ncr.masterJavaBaseModel?.id}`}>
                      {ncr.ncrDate
                        ? `${intlFormat({
                            date: ncr.ncrDate,
                            dateStyle: "medium",
                          })}`
                        : ""}
                    </Link>
                  </td>

                  <td className="border border-dark">
                    {
                      jobs.current.jobs.find(
                        (j) =>
                          `${j.masterJavaBaseModel?.id}` === `${ncr.extJobId}`
                      )?.name
                    }
                  </td>

                  <td className="border border-dark">
                    {ncr.extIssuerId
                      ? `${
                          ctx?.extUsers.find(
                            (u) => `${u.id}` === `${ncr.extIssuerId}`
                          )?.username
                        } (${
                          ctx?.extDepartments.find(
                            (d) =>
                              `${d.id}` ===
                              `${
                                ctx?.extUsers.find(
                                  (u) => `${u.id}` === `${ncr.extIssuerId}`
                                )?.departmentId
                              }`
                          )?.name
                        })`
                      : ``}
                  </td>
                  <td className="border border-dark">{ncr.ncArea || "-"}</td>
                  <td className="border border-dark">
                    {ncr.rootCauseAnalysis
                      ? ncr.rootCauseAnalysis.length > 25
                        ? ncr.rootCauseAnalysis.slice(0, 25) + "..."
                        : ncr.rootCauseAnalysis
                      : "-"}
                  </td>
                  <td className="border border-dark">
                    {ncr.problemCorrectiveActions?.length}
                  </td>
                  <td className="border border-dark">
                    {ncr.problemPreventiveActions?.length}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NcrGeneralPage;
