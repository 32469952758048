import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { ChevronLeft, Delete } from "@material-ui/icons";

import { v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  MasterJavaBaseModel,
  PpicJob,
  PpicJobs,
  PpicNcrGeneral,
  PpicNcrGeneralProblem,
  PpicNcrGeneralProblemCorrectiveAction,
  PpicNcrGeneralProblemPreventiveAction,
  PpicNcrGenerals,
} from "../../../masterbigsystem";
import {
  extNcrProblems,
  fetchJobsProtoSimple,
  fetchNcrGeneralProto,
  fetchNcrGeneralsProto,
} from "../../../helpers";
import Select from "react-select";
import { ExtUser } from "../../../models/model";
import { render } from "@testing-library/react";

/**
 * NcrDetailPage
 * Shows detail for a single NCR (NCRGeneral).  Allows adding Problems, Corrective actions, etc.
 */
const NcrGeneralDetailPage = () => {
  const { id } = useParams<{ id?: string }>();
  const ctx = useContext(AppContext);
  const history = useHistory();
  const jobs = useRef(PpicJobs.fromPartial({}));
  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const fetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({
      apiKey: ctx?.apiKey ?? "",

      all: true,
    });

    if (d) {
      jobs.current = d;
    }
    render();
  };

  // The main NCR record
  const [ncr, setNcr] = useState<PpicNcrGeneral>(
    PpicNcrGeneral.fromPartial({
      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({ uuid: v4() }),
      problems: [],
      problemCorrectiveActions: [],
      problemPreventiveActions: [],
    })
  );

  // If id is numeric, we are editing an existing NCR; if "new", we create a blank one
  const isNew = id === "new";

  useEffect(() => {
    if (!isNew && id) {
      loadData(id);
    }

    fetchJobsData();
  }, [id]);

  const loadData = async (ncrId: string | number) => {
    const existing = await fetchNcrGeneralProto({
      id: ncrId,
      apiKey: ctx?.apiKey ?? "",
    });
    if (existing) {
      setNcr(existing);
    }
  };

  // Save or update the NCR
  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/ncrgenerals-proto-save-bulk`,
        {
          method: "POST",
          headers: {
            Authorization: ctx?.apiKey ?? "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            PpicNcrGenerals.fromPartial({
              ncrGenerals: [ncr],
            })
          ),
        }
      );
      if (!resp.ok) {
        alert("Error saving NCR");
        return;
      }
      // After saving, go back or do what you need
      history.push("/ncrgeneral");
    } catch (e) {
      console.error(e);
      alert("Error saving NCR");
    }
  };

  // Add a blank Problem
  const handleAddProblem = () => {
    setNcr({
      ...ncr,
      problems: [
        ...(ncr.problems ?? []),
        PpicNcrGeneralProblem.fromPartial({
          masterJavaBaseModel: { uuid: v4() },
        }),
      ],
    });
  };

  // Remove a Problem by local UUID
  const handleRemoveProblem = (uuid?: string) => {
    setNcr({
      ...ncr,
      problems: (ncr.problems ?? []).filter(
        (p) => p.masterJavaBaseModel?.uuid !== uuid
      ),
    });
  };

  // Similarly for Corrective Action
  const handleAddCorrectiveAction = () => {
    setNcr({
      ...ncr,
      problemCorrectiveActions: [
        ...(ncr.problemCorrectiveActions ?? []),
        PpicNcrGeneralProblemCorrectiveAction.fromPartial({
          masterJavaBaseModel: { uuid: v4() },
        }),
      ],
    });
  };
  const handleRemoveCorrectiveAction = (uuid?: string) => {
    setNcr({
      ...ncr,
      problemCorrectiveActions: (ncr.problemCorrectiveActions ?? []).filter(
        (c) => c.masterJavaBaseModel?.uuid !== uuid
      ),
    });
  };

  // Similarly for Preventive Action
  const handleAddPreventiveAction = () => {
    setNcr({
      ...ncr,
      problemPreventiveActions: [
        ...(ncr.problemPreventiveActions ?? []),
        PpicNcrGeneralProblemPreventiveAction.fromPartial({
          masterJavaBaseModel: { uuid: v4() },
        }),
      ],
    });
  };
  const handleRemovePreventiveAction = (uuid?: string) => {
    setNcr({
      ...ncr,
      problemPreventiveActions: (ncr.problemPreventiveActions ?? []).filter(
        (p) => p.masterJavaBaseModel?.uuid !== uuid
      ),
    });
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <Link to="/ncrgeneral">
          <button className="btn btn-outline-primary mr-2">
            <ChevronLeft /> Back
          </button>
        </Link>
        <h4 className="m-0 mr-3">{isNew ? "New NCR" : `Edit NCR #${id}`}</h4>
        <button className="btn btn-primary" onClick={handleSave}>
          Save
        </button>
      </div>
      <hr className="border border-dark" />

      {/* Basic Fields */}
      <div className="form-group">
        <label>
          <strong>NCR Date</strong>
        </label>
        <input
          type="date"
          className="form-control form-control-sm"
          value={ncr.ncrDate ?? ""}
          onChange={(e) => {
            setNcr({ ...ncr, ncrDate: e.target.value });
          }}
        />
      </div>

      <div className="form-group">
        <label>
          <strong>Job</strong>
        </label>
        <Select
          options={jobs.current.jobs.map((j) => ({
            label: `${j.name}`,
            value: j,
          }))}
          placeholder="Select Job..."
          // set the 'value' by finding the user whose ID matches ncr.extIssuerId
          value={jobs.current.jobs
            .map((j) => ({
              label: `${j.name}`,
              value: j,
            }))
            .find(
              (j) => `${j.value.masterJavaBaseModel?.id}` === `${ncr.extJobId}`
            )}
          onChange={(val) => {
            const v = val as { value: PpicJob };
            setNcr({
              ...ncr,
              extJobId: v.value.masterJavaBaseModel?.id,
            });
          }}
        />
      </div>

      <div className="form-group">
        <label>
          <strong>Issuer (extIssuerId)</strong>
        </label>
        <Select
          options={ctx?.extUsers ?? []}
          placeholder="Select Issuer..."
          getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
          getOptionValue={(u) => `${u.id}`}
          // set the 'value' by finding the user whose ID matches ncr.extIssuerId
          value={
            ctx?.extUsers.find(
              (user) => `${user.id}` === `${ncr.extIssuerId}`
            ) || null
          }
          onChange={(val) => {
            const user = val as unknown as ExtUser;
            setNcr({
              ...ncr,
              extIssuerId:
                user && user.id !== undefined ? `${user.id}` : undefined,
            });
          }}
        />
      </div>

      <div className="form-group">
        <label>
          <strong>NC Area</strong>
        </label>
        <textarea
          className="form-control form-control-sm"
          value={ncr.ncArea ?? ""}
          onChange={(e) => {
            setNcr({ ...ncr, ncArea: e.target.value });
          }}
        />
      </div>

      <div className="form-group">
        <label>
          <strong>Root Cause Analysis</strong>
        </label>
        <textarea
          className="form-control form-control-sm"
          value={ncr.rootCauseAnalysis ?? ""}
          onChange={(e) => {
            setNcr({ ...ncr, rootCauseAnalysis: e.target.value });
          }}
        />
      </div>

      <hr className="border border-dark" />

      {/* Problems */}
      <div className="d-flex align-items-center">
        <h5 className="m-0">Problems</h5>
        <button
          className="btn btn-sm btn-primary ml-3"
          onClick={handleAddProblem}
        >
          Add Problem
        </button>
      </div>
      <table className="table table-sm mt-2">
        <thead>
          <tr>
            <th>Action</th>
            <th>Description</th>
            <th>extPicId</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {(ncr.problems ?? []).map((p, i) => (
            <tr key={p.masterJavaBaseModel?.uuid || i}>
              <td>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    handleRemoveProblem(p.masterJavaBaseModel?.uuid)
                  }
                >
                  <Delete />
                </button>
              </td>
              <td>
                <textarea
                  value={p.description ?? ""}
                  onChange={(e) => {
                    const updated = { ...p, description: e.target.value };
                    const newList = [...(ncr.problems ?? [])];
                    newList[i] = updated;
                    setNcr({ ...ncr, problems: newList });
                  }}
                />
              </td>
              <td>
                <td>
                  <div style={{ width: 300 }}>
                    <Select
                      options={ctx?.extUsers ?? []}
                      placeholder="Select PIC..."
                      getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
                      getOptionValue={(u) => `${u.id}`}
                      // find the user whose id matches p.extPicId
                      value={
                        ctx?.extUsers.find(
                          (user) => `${user.id}` === `${p.extPicId}`
                        ) || null
                      }
                      onChange={(val) => {
                        const user = val as unknown as ExtUser;

                        // update the single row
                        const updatedProblem = {
                          ...p,
                          extPicId:
                            user && user.id !== undefined
                              ? `${user.id}`
                              : undefined,
                        };

                        // now put it back into the array
                        const newProblems = [...(ncr.problems ?? [])];
                        newProblems[i] = updatedProblem;
                        setNcr({ ...ncr, problems: newProblems });
                      }}
                    />
                  </div>
                </td>
              </td>
              <td>
                <Select
                  options={extNcrProblems}
                  placeholder="Problem Type..."
                  // set the 'value' by finding the user whose ID matches ncr.extIssuerId
                  value={extNcrProblems.find((px) => px.value === p.type)}
                  onChange={(val) => {
                    const type = val as { value: string };

                    const updated = { ...p, type: type.value };
                    const newList = [...(ncr.problems ?? [])];
                    newList[i] = updated;
                    setNcr({ ...ncr, problems: newList });
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr className="border border-dark" />

      {/* Corrective Actions */}
      <div className="d-flex align-items-center">
        <h5 className="m-0">Corrective Actions</h5>
        <button
          className="btn btn-sm btn-primary ml-3"
          onClick={handleAddCorrectiveAction}
        >
          Add Corrective Action
        </button>
      </div>
      <table className="table table-sm mt-2">
        <thead>
          <tr>
            <th>Action</th>
            <th>Description</th>
            <th>Deadline</th>
            <th>extPicId</th>
          </tr>
        </thead>
        <tbody>
          {(ncr.problemCorrectiveActions ?? []).map((c, i) => (
            <tr key={c.masterJavaBaseModel?.uuid || i}>
              <td>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    handleRemoveCorrectiveAction(c.masterJavaBaseModel?.uuid)
                  }
                >
                  <Delete />
                </button>
              </td>
              <td>
                <textarea
                  value={c.description ?? ""}
                  onChange={(e) => {
                    const updated = { ...c, description: e.target.value };
                    const newList = [...(ncr.problemCorrectiveActions ?? [])];
                    newList[i] = updated;
                    setNcr({ ...ncr, problemCorrectiveActions: newList });
                  }}
                />
              </td>
              <td>
                <input
                  type="date"
                  value={c.deadline ?? ""}
                  onChange={(e) => {
                    const updated = { ...c, deadline: e.target.value };
                    const newList = [...(ncr.problemCorrectiveActions ?? [])];
                    newList[i] = updated;
                    setNcr({ ...ncr, problemCorrectiveActions: newList });
                  }}
                />
              </td>
              <td>
                <div style={{ width: 300 }}>
                  <Select
                    options={ctx?.extUsers ?? []}
                    placeholder="Select PIC..."
                    getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
                    getOptionValue={(u) => `${u.id}`}
                    value={
                      ctx?.extUsers.find(
                        (user) => `${user.id}` === `${c.extPicId}`
                      ) || null
                    }
                    onChange={(val) => {
                      const user = val as unknown as ExtUser;
                      const updated = {
                        ...c,
                        extPicId:
                          user && user.id !== undefined
                            ? `${user.id}`
                            : undefined,
                      };
                      const newList = [...(ncr.problemCorrectiveActions ?? [])];
                      newList[i] = updated;
                      setNcr({ ...ncr, problemCorrectiveActions: newList });
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr className="border border-dark" />

      {/* Preventive Actions */}
      <div className="d-flex align-items-center">
        <h5 className="m-0">Preventive Actions</h5>
        <button
          className="btn btn-sm btn-primary ml-3"
          onClick={handleAddPreventiveAction}
        >
          Add Preventive Action
        </button>
      </div>
      <table className="table table-sm mt-2">
        <thead>
          <tr>
            <th>Action</th>
            <th>Description</th>
            <th>Deadline</th>
            <th>extPicId</th>
          </tr>
        </thead>
        <tbody>
          {(ncr.problemPreventiveActions ?? []).map((p, i) => (
            <tr key={p.masterJavaBaseModel?.uuid || i}>
              <td>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    handleRemovePreventiveAction(p.masterJavaBaseModel?.uuid)
                  }
                >
                  <Delete />
                </button>
              </td>
              <td>
                <textarea
                  value={p.description ?? ""}
                  onChange={(e) => {
                    const updated = { ...p, description: e.target.value };
                    const newList = [...(ncr.problemPreventiveActions ?? [])];
                    newList[i] = updated;
                    setNcr({ ...ncr, problemPreventiveActions: newList });
                  }}
                />
              </td>
              <td>
                <input
                  type="date"
                  value={p.deadline ?? ""}
                  onChange={(e) => {
                    const updated = { ...p, deadline: e.target.value };
                    const newList = [...(ncr.problemPreventiveActions ?? [])];
                    newList[i] = updated;
                    setNcr({ ...ncr, problemPreventiveActions: newList });
                  }}
                />
              </td>
              <td>
                <div style={{ width: 300 }}>
                  <Select
                    options={ctx?.extUsers ?? []}
                    placeholder="Select PIC..."
                    getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
                    getOptionValue={(u) => `${u.id}`}
                    value={
                      ctx?.extUsers.find(
                        (user) => `${user.id}` === `${p.extPicId}`
                      ) || null
                    }
                    onChange={(val) => {
                      const user = val as unknown as ExtUser;
                      const updated = {
                        ...p,
                        extPicId:
                          user && user.id !== undefined
                            ? `${user.id}`
                            : undefined,
                      };
                      const newList = [...(ncr.problemPreventiveActions ?? [])];
                      newList[i] = updated;
                      setNcr({ ...ncr, problemPreventiveActions: newList });
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <label>
          <strong>Monitored by</strong>
        </label>
        <Select
          options={ctx?.extUsers ?? []}
          placeholder="Select Issuer..."
          getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
          getOptionValue={(u) => `${u.id}`}
          // set the 'value' by finding the user whose ID matches ncr.extIssuerId
          value={
            ctx?.extUsers.find(
              (user) => `${user.id}` === `${ncr.extMonitoredById}`
            ) || null
          }
          onChange={(val) => {
            const user = val as unknown as ExtUser;
            setNcr({
              ...ncr,
              extMonitoredById:
                user && user.id !== undefined ? `${user.id}` : undefined,
            });
          }}
        />
      </div>

      <div>
        <label>
          <strong>created by</strong>
        </label>
        <Select
          options={ctx?.extUsers ?? []}
          placeholder="Select Issuer..."
          getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
          getOptionValue={(u) => `${u.id}`}
          // set the 'value' by finding the user whose ID matches ncr.extIssuerId
          value={
            ctx?.extUsers.find(
              (user) =>
                `${user.id}` === `${ncr.masterJavaBaseModel?.extCreatedById}`
            ) || null
          }
          onChange={(val) => {
            const user = val as unknown as ExtUser;
            setNcr({
              ...ncr,
              masterJavaBaseModel: {
                ...ncr.masterJavaBaseModel,
                extCreatedById:
                  user && user.id !== undefined ? `${user.id}` : undefined,
              },
            });
          }}
        />
      </div>

      <div>
        <label>
          <strong>Closed by</strong>
        </label>
        <Select
          options={ctx?.extUsers ?? []}
          placeholder="Select Issuer..."
          getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
          getOptionValue={(u) => `${u.id}`}
          // set the 'value' by finding the user whose ID matches ncr.extIssuerId
          value={
            ctx?.extUsers.find(
              (user) => `${user.id}` === `${ncr.extClosedById}`
            ) || null
          }
          onChange={(val) => {
            const user = val as unknown as ExtUser;
            setNcr({
              ...ncr,
              extClosedById:
                user && user.id !== undefined ? `${user.id}` : undefined,
            });
          }}
        />
      </div>

      <div>
        <label>
          <strong>Approved by</strong>
        </label>
        <Select
          options={ctx?.extUsers ?? []}
          placeholder="Select Issuer..."
          getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
          getOptionValue={(u) => `${u.id}`}
          // set the 'value' by finding the user whose ID matches ncr.extIssuerId
          value={
            ctx?.extUsers.find(
              (user) => `${user.id}` === `${ncr.extApprovedById}`
            ) || null
          }
          onChange={(val) => {
            const user = val as unknown as ExtUser;
            setNcr({
              ...ncr,
              extApprovedById:
                user && user.id !== undefined ? `${user.id}` : undefined,
            });
          }}
        />
      </div>
    </div>
  );
};

export default NcrGeneralDetailPage;
